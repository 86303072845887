import { createContext } from 'react';

export const contextInitialValue = {
  product: null,
  calculatedInfo: {
    price: null,
    basePrice: null,
    inventory: null,
  },
  pricesInclTax: null,
  uomId: null,
  variantId: null,
  updateContext: () => null,

  //TICKET 118261-3.11. Display HHT code:::PDP
  hhtCode: null,

  //TICKET 158383 - Promotional Banner (Best Practices)
  promotionalBanner: null
};

const ProductContext = createContext(contextInitialValue);

export default ProductContext;