import styles from './WishList.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { routesBuilder } from 'routes';
import { useIsMobileViewport } from 'utils/hooks';
import ProductInfo from './ProductInfo';
import ActionLinks from './ActionLinks';
import ProductLine from './Line';
import { ProductTrackingContext } from 'components/objects/analytics';
import { EventSource } from 'behavior/analytics';
import DefaultTemplate from './templates/Default';
import MobileTemplate from './templates/Mobile';
import { removeProductFromWishList } from 'behavior/wishList/actions';
import { iEquals } from 'utils/helpers';

const GroupedProductLine = ({ line, product, removeProductFromWishList }) => {
  const { id, url, title: productTitle, image: productImage } = product;
  const { subLines } = line;
  const route = routesBuilder.forProduct.bind(null, id);
  const actionLinks = <ActionLinks productUrl={url} route={route} onDelete={() => { removeProductFromWishList(id); }} />;
  const isMobile = useIsMobileViewport();
  const LineTemplate = isMobile ? MobileTemplate : DefaultTemplate;

  const productInfo = (
    <ProductInfo
      productUrl={url}
      route={route}
      productTitle={productTitle}
      productId={id}
      variantTitle={line.title}
    />
  );

  return (
    <ProductTrackingContext.Provider value={{ product, trackingSource: EventSource.WishList }}>
      <LineTemplate
        productActionBlock={false}
        className={styles.groupedProductLine}
        productInfo={productInfo}
        actionLinks={actionLinks}
        isGroupedLine
        data-product={id}
      />
      {subLines && subLines.map(subLine => {
        const image = product.images.find(i => iEquals(i.variantId, subLine.variationId)) || productImage;
        const variant = product.variants?.find(variant => iEquals(variant.id, subLine.variationId));

        return (
          <ProductLine
            elementId={`product_${subLine.id}`}
            key={subLine.id}
            product={product}
            productLine={subLine}
            imageUrl={image && image.small}
            isVariantLine
            isOrderable={variant && variant.isOrderable}
          />
        );
      })}
    </ProductTrackingContext.Provider>
  );
};

GroupedProductLine.propTypes = {
  line: PropTypes.shape({
    title: PropTypes.string,
    subLines: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      variationId: PropTypes.string,
    })),
  }),
  product: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.shape({
      small: PropTypes.string,
    }),
    images: PropTypes.arrayOf(PropTypes.shape({
      variantId: PropTypes.string,
      small: PropTypes.string,
    })),
    variants: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      isOrderable: PropTypes.bool,
    })),
  }),
  removeProductFromWishList: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  removeProductFromWishList,
};

export default connect(null, mapDispatchToProps)(GroupedProductLine);
