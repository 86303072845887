import styles from './ProductComparisonTile.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useProductRoute } from '../hooks';
import { Link } from 'components/primitives/links';
import Spinner from 'components/primitives/spinner/Spinner';
import { ProductPrice, ProductStock, ProductThumbnail } from 'components/primitives/product';
import ButtonBox from './buttons';
import RemoveButton from '../RemoveButton';
import { ProductTrackingContext, WithProductClickTracking } from 'components/objects/analytics';
import { EventSource } from 'behavior/analytics';
import { useSanaTexts } from 'components/sanaText';

//TICKET 118261-3.11. Display HHT code:::COMPARISON
//TICKET 118252-3.2. Product grouping – Creating the groups
//TICKET 158383 - Promotional Banner (Best Practices)
const ProductTile = ({ product, noImage, onRemove, compact = false }) => {
  const {
    id,
    hhtCode,
    title,
    url,
    image,
    price,
    listPrice,
    inventory,
    stockLevels,
    isParentProduct,
    promotionalBanner
  } = product;

  const { texts: [
    FromPriice,
  ] } = useSanaTexts([
    'From_Price',
  ]);

  const route = useProductRoute(id);
  const link = (
    <WithProductClickTracking>
      {handleClick => (
        <Link onClick={handleClick} url={url} to={route} className={styles.productTitle}>
          {title}
        </Link>
      )}
    </WithProductClickTracking>
  );

  const imageLink = (
    <WithProductClickTracking>
      {handleClick => (
        <Link onClick={handleClick} draggable="false" className={styles.imageLink} url={url} to={route} aria-hidden>
          <ProductThumbnail
            title={title}
            src={image && (image.medium || image.small)}
            noImageSrc={noImage}
            className={styles.productImage}
            placeholder={<Spinner />}
            visibleByDefault
            promotionalBanner={promotionalBanner}
            promotionalBannerSize={'medium'}
          />
        </Link>
      )}
    </WithProductClickTracking>
  );

  const priceBlock = (price !== null || listPrice !== null) && (
    <div className={styles.prices}>
      {isParentProduct && <span>{FromPriice}</span>}
      <ProductPrice salesPrice={price} basePrice={!isParentProduct ? listPrice : ''} />
    </div>
  );

  const button = <ButtonBox product={product} />;

  return (
    <ProductTrackingContext.Provider value={{ product, trackingSource : EventSource.CompareProductsPage }}>
      <div className={`${styles.tile} ${compact ? styles.compactView : styles.fullView}`}>
        {compact
          ? (
            <>
              <div>
                {imageLink}
                {link}
                {priceBlock}
              </div>
              <div className={styles.productAction}>
                {button}
              </div>
            </>
          ) : (
            <>
              <div className={styles.thumbnail}>
                <div className={styles.thumbnailBox}>
                  {imageLink}
                  <RemoveButton className={styles.btnRemoveItem} onClick={() => onRemove(id)} />
                </div>
              </div>
              <div className={styles.productInfo}>
                <div className={styles.productDescription}>
                  {link}
                  {/*TICKET 118261-3.11. Display HHT code:::COMPARISON*/}
                  <div className={styles.productId}>{hhtCode}</div>
                  {inventory !== null && stockLevels != null && (
                    <div>
                      <ProductStock inventory={inventory} stockLevels={stockLevels} />
                    </div>
                  )}
                </div>
                <div className={styles.productAction}>
                  {priceBlock}
                  {button}
                </div>
              </div>
            </>
          )
        }
      </div>
    </ProductTrackingContext.Provider>
  );
};

//TICKET 118261-3.11. Display HHT code:::COMPARISON
//TICKET 118252-3.2. Product grouping – Creating the groups
//{/*TICKET 158383 - Promotional Banner(Best Practices)*/}
ProductTile.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    hhtCode: PropTypes.string,
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
      medium: PropTypes.string,
      small: PropTypes.string,
    }),
    url: PropTypes.string.isRequired,
    price: PropTypes.number,
    listPrice: PropTypes.number,
    inventory: PropTypes.number,
    stockLevels: PropTypes.object,
    promotionalBanner: PropTypes.string,
  }).isRequired,
  noImage: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  compact: PropTypes.bool,
  isParentProduct: PropTypes.bool,
};

const mapStateToProps = ({ settings }) => ({
  noImage: settings && settings.product && settings.product.noImage && settings.product.noImage.medium,
});

export default connect(mapStateToProps)(ProductTile);