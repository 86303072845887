import styles from '../Checkout.module.scss';
import { useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import CheckoutContext from './CheckoutContext';
import { useDispatch } from 'react-redux';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { submitCheckout, Steps } from 'behavior/pages/checkout';
import scrollIntoView from 'scroll-into-view';
import { navigateTo } from 'behavior/events';
import { validateForm } from 'components/objects/forms';
import { trackCheckout } from 'behavior/analytics';
import { useEffectOnChange, useIsOffline, useOnChange } from 'utils/hooks';
import { trackStepOption } from 'behavior/pages/checkout/utils';
import { useIncompleteStepsInfo, useGetStepNavigationInfo } from '../hooks';
import { useIsPreview } from 'components/objects/preview';
import { showActionForbiddenInPreviewToast } from 'behavior/preview';

const stepsNames = Object.values(Steps);
const getStepIndex = step => stepsNames.indexOf(step);

export const CheckoutProvider = ({ info, children }) => {
  const isLoadingRef = useRef();
  const isPendingSubmit = useRef();
  const isSubmitting = useRef();
  const prevStep = useRef();
  const dispatch = useDispatch();

  const isOffline = useIsOffline();
  const isPreview = useIsPreview();

  const shippingStepAvailable = !!info.shippingMethods,
    paymentStepAvailable = !isOffline && !!info.paymentMethods,
    extraPaymentStepAvailable = paymentStepAvailable && !!info.extraPaymentStep,
    customerDataStepAvailable = paymentStepAvailable && !!info.customerDataStep;

  let { currentStep = stepsNames[0] } = info;
  const incompleteSteps = useIncompleteStepsInfo(info.steps);

  if (!isAvailable(currentStep)) {
    let stepToDisplay = Steps.Address;

    for (const step of stepsNames) {
      if (incompleteSteps.has(step) || (step === currentStep))
        break;

      if (!isAvailable(step))
        continue;

      stepToDisplay = step;
    }

    currentStep = stepToDisplay;
  }

  const getStepNavigationInfo = useGetStepNavigationInfo(!!info.quote, info.isQuote, info.isGuest);

  const invalidStep = useMemo(() => {
    if (isPreview)
      return;

    if (currentStep !== Steps.None)
      for (const step of info.steps) {
        if (step.id === currentStep)
          break;

        if (!step.isCompleted) {
          currentStep = step.id;

          return { id: step.id };
        }
      }
  }, [currentStep, info.steps, isPreview]);

  useEffect(() => {
    if (invalidStep) {
      const { to, url } = getStepNavigationInfo(invalidStep.id);
      if (!to.options)
        to.options = {};

      to.options.stepInvalid = true;
      dispatch(navigateTo(to, url));
    }
  }, [invalidStep]);

  useOnChange(() => {
    if (!isPreview && prevStep.current) {
      const trackOptionAction = trackStepOption(prevStep.current, info);
      if (trackOptionAction)
        dispatch(trackOptionAction);
    }

    prevStep.current = currentStep;
  }, [currentStep]);

  const currentIndex = getStepIndex(currentStep);

  const navigateToNextStepPage = () => {
    if (invalidStep)
      return;

    if (isSubmitting.current)
      dispatch(unsetLoadingIndicator());

    isSubmitting.current = false;
    if (!isLoadingRef.current)
      return;

    isLoadingRef.current = false;
    if (isPendingSubmit.current) {
      let nextStepId;
      for (let index = currentIndex + 1; true; index++) {
        nextStepId = stepsNames[index];
        if (isAvailable(nextStepId))
          break;
      }
      const { to, url } = getStepNavigationInfo(nextStepId);
      dispatch(navigateTo(to, url));
      isPendingSubmit.current = false;
    }
  };

  useEffect(() => {
    const stepIndex = currentIndex;
    if (stepIndex > 0) {
      const header = document.getElementsByClassName(styles.header)[stepIndex];
      header && scrollIntoView(header, { time: 300 });
    }
  }, [currentStep]);

  const productsToTrack = info.analytics ? info.analytics.products : null;
  useEffect(() => {
    if (!productsToTrack)
      return;

    dispatch(trackCheckout({
      step: getStepIndex(currentStep) + 1,
      products: productsToTrack,
    }));
  }, [productsToTrack, currentStep]);

  useEffectOnChange(() => {
    if (!info.incompleteSteps?.length)
      return;

    const step = info.incompleteSteps[0].type;
    if (currentStep === step)
      return;

    isLoadingRef.current = false;
    isSubmitting.current = false;
    isPendingSubmit.current = false;

    const { to, url } = getStepNavigationInfo(step);
    if (!to.options)
      to.options = {};

    to.options.stepInvalid = true;
    dispatch(navigateTo(to, url));
  }, [info.incompleteSteps]);

  useEffect(navigateToNextStepPage, [info]);

  const contextValue = {
    setLoading() {
      dispatch(setLoadingIndicator());
      isLoadingRef.current = true;

      return () => {
        dispatch(unsetLoadingIndicator());
        isLoadingRef.current = false;
      };
    },
    nextStep(forceRedirectToNextStep = false) {
      contextValue.setLoading();
      if (currentIndex >= stepsNames.length - 2) // Overview or None
        return;

      isPendingSubmit.current = true;
      if (forceRedirectToNextStep || isPreview)
        navigateToNextStepPage();
    },
    getPrevStepNavigationInfo() {
      let stepId;
      for (let index = currentIndex; true; index--) {
        if (index === 0)
          return null;

        stepId = stepsNames[index - 1];
        if (isAvailable(stepId))
          break;
      }

      return getStepNavigationInfo(stepId);
    },
    async submit(additionalInfoFormikRef, termsFormikRef) {
      const [infoValid, termsValid] = await Promise.all([
        validateForm(additionalInfoFormikRef),
        validateForm(termsFormikRef),
      ]);

      if (!infoValid)
        scrollIntoError(additionalInfoFormikRef);
      else if (!termsValid)
        scrollIntoError(termsFormikRef);

      if (!infoValid || !termsValid)
        return false;

      if (isPreview) {
        dispatch(showActionForbiddenInPreviewToast());
        return false;
      }

      isSubmitting.current = true;
      dispatch(submitCheckout(additionalInfoFormikRef.current && additionalInfoFormikRef.current.values));
      return true;
    },
    activeStep: currentStep,
    stepVisited(stepId) {
      return currentIndex < stepsNames.length - 1 // Not None step.
        && isAvailable(stepId)
        && getStepIndex(stepId) < currentIndex;
    },
  };

  return (
    <CheckoutContext.Provider value={contextValue}>
      {children}
    </CheckoutContext.Provider>
  );

  function isAvailable(stepId) {
    switch (stepId) {
      case Steps.Shipping:
        return shippingStepAvailable;
      case Steps.Payment:
        return paymentStepAvailable;
      case Steps.ExtraPayment:
        return extraPaymentStepAvailable;
      case Steps.CustomerData:
        return customerDataStepAvailable;
      default:
        return true;
    }
  }
};

CheckoutProvider.propTypes = {
  info: PropTypes.shape({
    isQuote: PropTypes.bool,
    incompleteSteps: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired),
    analytics: PropTypes.shape({
      products: PropTypes.array,
    }),
    extraPaymentStep: PropTypes.object,
    customerDataStep: PropTypes.object,
    steps: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      isCompleted: PropTypes.bool,
    })),
  }).isRequired,
  children: PropTypes.node,
};

function scrollIntoError(formikRef) {
  const errorKeys = Object.keys(formikRef.current.errors);
  if (!errorKeys.length)
    return;

  const invalidElement = document.querySelector(`[name="${errorKeys[0]}"][aria-invalid=true]`);
  if (invalidElement)
    scrollIntoView(invalidElement, { time: 300 }, invalidElement.focus && (() => invalidElement.focus()));
}
