import styles from '../OrderResult.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useSimpleTexts } from 'components/sanaText';
import { logout } from 'behavior/user';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { Placeholder } from 'components/primitives/placeholders';
import { AbilityTo } from 'behavior/user/constants';
import { useHasAbilities } from 'components/objects/user';
import { CheckoutProcessNames } from 'behavior/pages/checkout';
import { checkoutProcessNamePropType } from '../propTypes';
import { DocumentType } from 'behavior/documents';
import { formatText } from 'utils/formatting';
import { Helmet } from 'react-helmet';
import { usePageTitle } from 'components/objects/hooks';
import { useSelector } from 'react-redux';

const PaymentError = ({ id: transactionId, documentId, documentNumber, checkoutProcessName }) => {
  const dispatch = useDispatch();

  const {
    abilityKey,
    descriptionTextKey,
    documentTextKey,
    documentRoute,
  } = getPaymentErrorData(documentId, checkoutProcessName);

  const [canSeeDocument] = useHasAbilities(abilityKey);
  const [texts, loaded] = usePaymentErrorTexts(descriptionTextKey, documentTextKey);
  const pageTitle = usePageTitle();
  const isAuthenticated = useSelector(s => s.user.isAuthenticated);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <p className={styles.description}>
        {formatText(texts.description, [transactionId, documentNumber])}
      </p>
      {loaded
        ? (
          <ul className={styles.actionsList}>
            <li>
              <Link
                className={`${linkStyles.arrowed} ${styles.contactUsLink}`}
                to={routesBuilder.forContactUs}
              >
                {texts.contactUs}
              </Link>
            </li>
            {canSeeDocument && (
              <li>
                <Link
                  className={`${linkStyles.arrowed} ${styles.orderLink}`}
                  to={documentRoute}
                  options={{ backTo: { routeData: routesBuilder.forPaymentError(transactionId) } }}
                >
                  {texts.documentLink}
                </Link>
              </li>
            )}
            <li>
              <Link
                className={`${linkStyles.arrowed} ${styles.homeLink}`}
                to={routesBuilder.forHome}
              >
                {texts.homePage}
              </Link>
            </li>
            {isAuthenticated &&
              <li>
                <button
                  className={`${linkStyles.link} ${linkStyles.arrowed} ${styles.btnLogout}`}
                  onClick={() => dispatch(logout())}
                >
                  {texts.logout}
                </button>
              </li>
            }
          </ul>
        )
        : (
          <ul className={styles.actionsList}>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
          </ul>
        )
      }
    </>
  );
};

PaymentError.propTypes = {
  id: PropTypes.string.isRequired,
  documentId: PropTypes.string.isRequired,
  documentNumber: PropTypes.string.isRequired,
  checkoutProcessName: checkoutProcessNamePropType.isRequired,
};

const Memoized = memo(PaymentError);
Memoized.selectPropsFromPage = ({ transaction }) => transaction;

export default Memoized;

function getPaymentErrorData(documentId, checkoutProcessName) {
  if (checkoutProcessName === CheckoutProcessNames.Invoice)
    return {
      abilityKey: AbilityTo.ViewInvoices,
      descriptionTextKey: 'InvoicePaymentError_Description',
      documentTextKey: 'GotoInvoicesPage',
      documentRoute: routesBuilder.forInvoices(),
    };

  return {
    abilityKey: AbilityTo.ViewOrders,
    descriptionTextKey: 'OrderPaymentError_Description',
    documentTextKey: 'GotoOrderPage',
    documentRoute: routesBuilder.forDocument(documentId, DocumentType.Order),
  };
}

function usePaymentErrorTexts(descriptionTextKey, documentTextKey) {
  const {
    texts: [
      description,
      contactUs,
      documentLink,
      homePage,
      logout,
    ],
    loaded,
  } = useSimpleTexts([
    descriptionTextKey,
    'ContactUs',
    documentTextKey,
    'GoToHomePage',
    'SubmitLogoutLinkText',
  ]);

  return [
    {
      description,
      contactUs,
      documentLink,
      homePage,
      logout,
    },
    loaded,
  ];
}
