import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { LazyImage } from 'components/primitives/responsiveImages';

{/*TICKET 158383 - Promotional Banner(Best Practices)*/ }
const ProductThumbnail = ({ src, noImageSrc, title, promotionalBanner,promotionalBannerSize, ...props }) => {
  const { texts: [productNoImageText] } = useSanaTexts(['Product_NoImage'], makeSimpleText);

  if (!src && !noImageSrc)
    return null;
  
  const imgTitle = src ? title : productNoImageText;

  //TICKET 158383 - Promotional Banner(Best Practices)
  return <LazyImage src={src || noImageSrc} title={imgTitle} alt={imgTitle} promotionalBanner={promotionalBanner} promotionalBannerSize={promotionalBannerSize} {...props} />;

};

ProductThumbnail.propTypes = {
  src: PropTypes.string,
  noImageSrc: PropTypes.string,
  title: PropTypes.string,
  promotionalBanner: PropTypes.string,
};

export default memo(ProductThumbnail);