import { memo,useCallback,useState } from 'react';
import { useProductContext } from './hooks';
import { Select } from 'components/primitives/form';
import { useDispatch } from 'react-redux';
import { updateCalculatedFields } from 'behavior/pages/product';
import styles from 'components/objects/mediaGallery/GalleryImage.module.scss';
import Spinner from 'components/primitives/spinner/Spinner';
import { useSanaTexts } from 'components/sanaText';

const SizeDropdown = () => {
  const { product } = useProductContext();
  const [isLoading, setLoading] = useState(false);
  const [selectedId, setId] = useState('');
  const dispatch = useDispatch();
  const { texts: [
    GroupSize,
  ] } = useSanaTexts([
    'Group_Size',
  ]);

  const OnSizeSelected = useCallback(value => {
    if (!value)
      return;

    setId(value);
    setLoading(!isLoading);
    const uomId = product.uom['id'];
    const options = {
      ids: value,
      page: { size: 1, index: 0 },
      uomId,
      isChildRequest: true,
    };
    dispatch(updateCalculatedFields(options));
  }, [selectedId]);

  if (!product.isParentProduct || !product.childItemList)
    return null;
  const sizeOptions = [{ name: 'Select', value: null }, ...product.childItemList.map(c => ({ name: c.size, value: c.productId }))];

  if (!sizeOptions.length)
    return null;

  //ToDo remove code 
  //function OnSizeSelected(value) {
  //  if (!value)
  //    return;

  //  setId(value);
  //  setLoading(!isLoading);
  //  const uomId = product.uom['id'];
  //  const options = {
  //    ids: value,
  //    page: { size: 1, index: 0 },
  //    uomId,
  //    isChildRequest: true,
  //  };
  //  useCallback(() =>
  //    dispatch(updateCalculatedFields(options)), [value]);
  //  return;
  //}

  return (
    <div className="Grid_row Grid_align-items-center Details_row ">
      <span className="Grid_col-12 Grid_col-sm-3 Grid_col-lg-2 Grid_align-self-auto Details_quantity-box-col"> {GroupSize} </span>
      {isLoading && product.id !== selectedId && <div className={styles.spinner}><Spinner /></div>}
      <Select
        id="size_dropdown"
        items={sizeOptions}
        onChange={OnSizeSelected}
        value={''}
        className={styles.selectPdp}
      />
    </div>
  );
};

export default memo(SizeDropdown);