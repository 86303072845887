import createSystemRenderer from 'components/objects/systemPages/createSystemRenderer';
import { CheckoutProcessNames } from 'behavior/pages/checkout';
import PaymentError from './PaymentError';

export default page => {
  const { transaction: { checkoutProcessName } } = page;

  const headerTextKey = checkoutProcessName === CheckoutProcessNames.Invoice
    ? 'InvoicePaymentError_Header'
    : 'OrderPaymentError_Header';

  return createSystemRenderer(headerTextKey, PaymentError)(page);
};
