//TICKET-118266:3.16.�Outstanding balance � Overview page:::PAGE
import { memo } from 'react';
import { routesBuilder } from 'routes';
import OutstandingBalance, { selectPropsFromPage } from '../OutstandingBalance';
import OutstandingsList from './OutstandingsList';

const textKeys = {
  introduction: 'OrderHistory_IntroductionText',
  listHeader: 'RecentOutstandingTransactions',
  loadMore: 'ShowNextNOrders',
  noItems: 'Orders_NoOrderHistory',
};

const outstandingsRoute = routesBuilder.forOutstandings();

const Outstandings = memo(props => (

  <OutstandingBalance
    loadMoreRoute={outstandingsRoute}
    textKeys={textKeys}
    {...props}
  >
    
    <OutstandingsList outstandings={props.items} />
  </OutstandingBalance>
));

Outstandings.selectPropsFromPage = selectPropsFromPage;

export default Outstandings;
