import styles from './BrandImage.module.scss';
import { memo } from 'react';
import { useProductContext } from './hooks';

//118263_3_13_PDP_show_brand_image
const BrandImage = () => {
  
  const { product } = useProductContext();
  const { manufacturerCode  } = product || {};
  const imagePath = '/content/files/manufacturers/' + manufacturerCode + '.jpg';

  if (manufacturerCode ==='')
    return null;
  return (
    <div>
      <img className={styles.image} src={imagePath} alt={manufacturerCode} />      
    </div>
  );
};

export default memo(BrandImage);