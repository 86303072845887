import styles from '../BasketFooter.module.scss';
import PropTypes from 'prop-types';
import TotalsLine from './TotalsLine';
import { SimpleText } from 'components/sanaText';

const TotalInclTax = ({ price, focus = true }) => {
  if (focus) {
    return (
      <TotalsLine
        label={<SimpleText textKey="Total" />}
        value={price}
        className={`${styles.priceInclTax} ${styles.emphasis} focused-total-line`}
      />);
  }
  return <TotalsLine label={<SimpleText textKey="TotalIncVat" />} value={price} className={styles.priceInclTax} />;
};

TotalInclTax.propTypes = {
  price: PropTypes.number.isRequired,
  focus: PropTypes.bool,
};

export default TotalInclTax;
