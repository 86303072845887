//TICKET 118265:3.15. Outstanding balance – My account dashboard:::DASHBOARD
import { OUTSTANDINGBALANCE_REQUESTED, outstandingBalanceReceived } from '../actions';
import {
  outstandingBalanceQuery
} from '../queries';
import { switchMap, pluck, takeUntil, mergeMap, startWith } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { LOCATION_CHANGED } from 'behavior/events';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';

export default function (action$, _, { api, logger }) {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
  const setLoading = setLoadingIndicator();
  const unsetLoading = unsetLoadingIndicator();

  return action$.pipe(
    ofType(OUTSTANDINGBALANCE_REQUESTED),
    switchMap(({ payload: { options } }) => {
      const query = outstandingBalanceQuery;
      if (!query) {
        logger.error(`Query for document of outstanding balance type is not registered.`);
        return EMPTY;
      }

      return api.graphApi(query, { options }).pipe(
        pluck('outstandings', 'docs'),
        mergeMap(outstandings => of(
          outstandingBalanceReceived(outstandings && outstandings.list, options.page.index),
          unsetLoading,
        )),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        takeUntil(locationChanged$),
        startWith(setLoading),
      );
    }),
  );
}