//TICKET 118265:3.15. Outstanding balance – My account dashboard:::DASHBOARD
export const OUTSTANDINGBALANCE_REQUESTED = 'OUTSTANDINGBALANCE_REQUESTED';
export const requestOutstandingBalance = (options) => ({
  type: OUTSTANDINGBALANCE_REQUESTED,
  payload: { options },
});

//TICKET 118265:3.15. Outstanding balance – My account dashboard:::DASHBOARD
export const OUTSTANDINGBALANCE_RECEIVED = 'OUTSTANDINGBALANCE_RECEIVED';
export const outstandingBalanceReceived = (documents, page) => ({
  type: OUTSTANDINGBALANCE_RECEIVED,
  payload: { documents, page },
});