import type { MonoTypeOperatorFunction } from 'rxjs';
import { tap } from 'rxjs/operators';
import { parseContent } from 'behavior/content';
import { SystemPage, SystemPageData } from './types';

export function initSystemPageContent<TData extends { page: SystemPageData }, TResult extends { page: SystemPage }>(): MonoTypeOperatorFunction<TResult> {
  return tap<TData | null>(data => {
    if (!data)
      return;

    const page = data.page;

    if (page && page.content) {
      const { header, footer } = page.content;

      if (header)
        page.content.header = parseContent(header);

      if (footer)
        page.content.footer = parseContent(footer);
    }
  }) as any;
}
