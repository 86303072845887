import styles from './RelatedProduct.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { UseSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { Tabs } from 'components/objects/tabs';
import RelatedProduct from './RelatedProduct';
import { ProductTrackingContext } from 'components/objects/analytics';
import { trackProductListView, EventSource } from 'behavior/analytics';
import { useLoadEffect } from 'utils/hooks';
import { useDispatch } from 'react-redux';

const RelatedProducts = ({ relatedProductGroups, noImageUrl, productId, languageId }) => {
  const dispatch = useDispatch();
  const groupTextKeys = ['Related'].concat(relatedProductGroups.map(({ relationType }) => `Product_RelatedProducts_${relationType}`));

  const allProducts = [];
  relatedProductGroups.forEach(({ products }) => products.forEach(product => allProducts.push(product)));

  useLoadEffect(() => {
    if (!allProducts || !allProducts.length)
      return;

    dispatch(trackProductListView({
      products: allProducts,
      source: EventSource.RelatedProducts,
    }));
 }, [allProducts]);

  return (
    <UseSanaTexts options={groupTextKeys} key={groupTextKeys.length}>
      {([relatedText, ...groupTitles]) => (
        <Tabs name="related" areScrollable resetDependency={productId} preventTransitionDependency={languageId}>
          {relatedProductGroups.map(({ relationType, products }, index) => {
            const groupId = createGroupId(relationType);
            const title = makeSimpleText(groupTitles[index] || `${relatedText} ${relationType}`);

            return (
              <Tabs.Item tabKey={groupId} key={relationType} title={title}>
                <div className={styles.panel + ' panel'}>
                  {products.map((product, index) => (
                    <ProductTrackingContext.Provider key={`${groupId}-${index}`} value={{ product, trackingSource: EventSource.RelatedProducts }}>
                      <RelatedProduct noImageUrl={noImageUrl} product={product} />
                    </ProductTrackingContext.Provider>
                  ))}
                </div>
              </Tabs.Item>
            );
          })}
        </Tabs>
      )}
    </UseSanaTexts>
  );
};

function createGroupId(relationType) {
  return `relatedProductsTab${relationType}`;
}

RelatedProducts.propTypes = {
  relatedProductGroups: PropTypes.arrayOf(PropTypes.shape({
    relationType: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        routeData: PropTypes.object.isRequired,
        imageUrl: PropTypes.string,
        title: PropTypes.string.isRequired,
      }),
    ).isRequired,
  })),
  noImageUrl: PropTypes.string,
  productId: PropTypes.string.isRequired,
  languageId: PropTypes.number,
};

export default memo(RelatedProducts);