import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useProductRouteBuilder } from './hooks';
import { appendAgreementLineToUrl } from 'behavior/salesAgreements';

//TICKET 118252 - 3.2.Product grouping – Creating the groups
const WithProductRoute = ({ children, product: { id, url, isParentProduct }, withoutBackTo, salesAgreementLineId }) => {
  const routeBuilder = useProductRouteBuilder(id, withoutBackTo, salesAgreementLineId, isParentProduct);
  const productUrl = salesAgreementLineId ? appendAgreementLineToUrl(url, salesAgreementLineId) : url;

  return children(productUrl, routeBuilder);
};

WithProductRoute.propTypes = {
  children: PropTypes.func.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    isParentProduct: PropTypes.bool,
  }).isRequired,
  withoutBackTo: PropTypes.bool,
  salesAgreementLineId: PropTypes.string,
};

export default connect(mapStateToProps)(WithProductRoute);

function mapStateToProps({ page: { salesAgreement } }) {
  return {
    salesAgreementLineId: salesAgreement?.preSelectedLine?.id,
  };
}
