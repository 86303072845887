exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PromotionalBanner_image{width:100px;height:100px;float:none;margin:3px;padding:3px}", ""]);

// exports
exports.locals = {
	"image": "PromotionalBanner_image"
};