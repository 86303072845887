import styles from './PromotionalBanner.module.scss';
import { memo } from 'react';
import { useProductContext } from './hooks';

//TICKET 158383 - Promotional Banner (Best Practices)
const PromotionalBanner = ({ imageSize }) => {
  
  const { product } = useProductContext();
  const { promotionalBanner  } = product || {};
  const imagePath = `/product/image/${imageSize}/${promotionalBanner}.png`;
  if (!promotionalBanner)
    return null;

  return (
    <div>
      <img className={styles.image} src={imagePath} alt={promotionalBanner} />
    </div>
  );
};

export default memo(PromotionalBanner);