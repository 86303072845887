import styles from '../OrderResult.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSimpleTexts, SimpleText } from 'components/sanaText';
import { AbilityTo } from 'behavior/user/constants';
import { useHasAbilities } from 'components/objects/user';
import { CheckoutProcessNames } from 'behavior/pages/checkout';
import { checkoutProcessNamePropType } from '../propTypes';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { CustomerTypes, logout } from 'behavior/user';
import { DocumentType } from 'behavior/documents';
import { Placeholder } from 'components/primitives/placeholders';
import { useEffect } from 'react';
import { trackPurchase } from 'behavior/analytics';
import { formatText } from 'utils/formatting';
import { useIsPreview } from 'components/objects/preview';

const OrderSubmit = ({
  transaction,
  offlineMode,
  isB2CCustomer,
  isAuthenticated,
  logout,
  trackPurchase,
}) => {
  const {
    id: transactionId,
    checkoutProcessName,
    submittedOffline,
    documentId,
    documentNumber,
    failed,
    cancelled,
    isPaymentError,
  } = transaction;

  useEffect(() => {
    if (failed || cancelled || isPaymentError)
      return;

    trackPurchase(transaction);
  }, []);

  const {
    abilityKey,
    descriptionTextKey,
    documentTextKey,
    textTypePrefix,
    documentRoute,
  } = getOrderSubmitData(documentId, checkoutProcessName);

  const showOfflineMessage = offlineMode || submittedOffline;
  const [canSeeDocument, viewMyAccountAbility] = useHasAbilities(abilityKey, AbilityTo.ViewMyAccountPage);
  const showDocumentLink = canSeeDocument && !submittedOffline;
  const showMyAccountLink = viewMyAccountAbility && isAuthenticated;
  const isPreview = useIsPreview();

  const [texts, loaded] = useSubmitTexts(
    !showOfflineMessage && descriptionTextKey,
    showDocumentLink && documentTextKey,
    showMyAccountLink,
    isAuthenticated);

  const customerSuffix = isB2CCustomer ? 'B2C' : 'B2B';

  return (
    <>
      <p className={`${styles.description} ${textTypePrefix}`}>
        {showOfflineMessage
          ? <SimpleText textKey={`Checkout_${textTypePrefix}Submit_OfflineMessage_${customerSuffix}`} />
          : formatText(texts.description, [documentNumber])
        }
      </p>
      {loaded
        ? (
          <ul className={styles.actionsList}>
            {showDocumentLink && (
              <li>
                <Link
                  className={`${linkStyles.arrowed} ${styles.orderLink}`}
                  to={isPreview ? null : documentRoute}
                  options={{ backTo: { routeData: routesBuilder.forOrderSubmit(transactionId) } }}
                >
                  {texts.documentLink}
                </Link>
              </li>
            )}
            {showMyAccountLink && (
              <li>
                <Link
                  className={`${linkStyles.arrowed} ${styles.myAccountLink}`}
                  to={routesBuilder.forMyAccount}
                  options={{ backTo: { routeData: routesBuilder.forOrderSubmit(transactionId) } }}
                >
                  {texts.account}
                </Link>
              </li>
            )}
            <li>
              <Link className={`${linkStyles.arrowed} ${styles.homeLink}`} to={routesBuilder.forHome}>
                {texts.goToHomepage}
              </Link>
            </li>
            {isAuthenticated &&
              <li>
                <button className={`${linkStyles.link} ${linkStyles.arrowed} ${styles.btnLogout}`} onClick={() => logout()}>
                  {texts.logout}
                </button>
              </li>
            }
          </ul>
        )
        : (
          <ul className={styles.actionsList}>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
          </ul>
        )
      }
    </>
  );
};

OrderSubmit.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string.isRequired,
    submittedOffline: PropTypes.bool,
    checkoutProcessName: checkoutProcessNamePropType.isRequired,
    documentId: PropTypes.string.isRequired,
    documentNumber: PropTypes.string.isRequired,
  }).isRequired,
  offlineMode: PropTypes.bool,
  isB2CCustomer: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  trackPurchase: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  app: { offlineMode },
  page: { transaction },
  user: { customerType, isAuthenticated },
}) => ({
  transaction,
  offlineMode,
  isB2CCustomer: customerType === CustomerTypes.B2C,
  isAuthenticated,
});

export default connect(mapStateToProps, { logout, trackPurchase })(OrderSubmit);

function useSubmitTexts(descriptionTextKey, documentLinkTextKey, showMyAccountLink, isAuthenticated) {
  const {
    texts: [
      description,
      documentLink,
      goToHomepage,
      account,
      logout,
    ],
    loaded,
  } = useSimpleTexts([
    descriptionTextKey,
    documentLinkTextKey,
    'GoToHomePage',
    showMyAccountLink && 'GotoAccountPage',
    isAuthenticated && 'SubmitLogoutLinkText',
  ]);

  return [{
    description,
    documentLink,
    goToHomepage,
    account,
    logout,
  }, loaded];
}

function getOrderSubmitData(documentId, checkoutProcessName) {
  let abilityKey, descriptionTextKey, documentTextKey, textTypePrefix = 'Order', documentRoute;

  if (checkoutProcessName === CheckoutProcessNames.Quote || checkoutProcessName === CheckoutProcessNames.EditQuote) {
    abilityKey = AbilityTo.ViewQuotes;
    descriptionTextKey = 'SubmitQuote_QuoteSuccess';
    textTypePrefix = 'Quote';
    documentTextKey = 'GotoQuotePage';
    documentRoute = routesBuilder.forDocument(documentId, DocumentType.Quote);
  } else if (checkoutProcessName === CheckoutProcessNames.Invoice) {
    abilityKey = AbilityTo.ViewInvoices;
    descriptionTextKey = 'SubmitInvoice_Success';
    documentTextKey = 'GotoInvoicesPage';
    documentRoute = routesBuilder.forInvoices();
  } else {
    abilityKey = AbilityTo.ViewOrders;
    descriptionTextKey = 'SubmitOrder_OrderSuccessDescription';
    documentTextKey = 'GotoOrderPage';
    documentRoute = routesBuilder.forDocument(documentId, DocumentType.Order);
  }

  return {
    abilityKey,
    descriptionTextKey,
    documentTextKey,
    textTypePrefix,
    documentRoute,
  };
}
