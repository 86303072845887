import { storageKey, storageKeyForMaster } from './constants';

export function createApiVariables(localStorage, count, skipFirst) {
    const products = localStorage.getItem(storageKey) || [];
    const parentProducts = localStorage.getItem(storageKeyForMaster) || []; //TICKET 118259 [Marlboro] Product grouping � Last viewed/checkout offers/add-ons
    if (!products.length && !parentProducts.length)
    return undefined;

  let filteredProducts = products, start = 0;
  const filteredParentProducts = parentProducts;

  if (skipFirst)
    start = 1;

    if (start || count) {
      filteredProducts = filteredProducts.slice(start, count ? count + start : count);
      //ToDo TICKET 118259 
      //if (skipFirst && filteredParentProducts.length && filteredProducts[0] === filteredParentProducts[0] + '_Parent')
      //  filteredParentProducts = parentProducts.slice(start, count ? count + start : count);
    }
    
  if (!filteredProducts.length)
    return undefined;

  return {
    options: {
      ids: filteredProducts,
      parentIds: filteredParentProducts,
      notGroupRequest: true,   //TICKET 118259 [Marlboro] Product grouping � Last viewed/checkout offers/add-ons
      page: {
        index: 0,
        size: filteredProducts.length,
      },
    },
  };
}

export function sortProducts(products, variables) {
  if (!products || !products.length)
    return products;
  const ids = variables.options.ids;
  return products
    .sort((first, second) => ids.indexOf(first.id.toLowerCase()) - ids.indexOf(second.id.toLowerCase()));
}
