import type { RowContentElementData, ContentBlockData } from './types';

export const parseContent = (content: RowContentElementData[]) => {
  if (!content)
    return content;

  return content.map(
    row => ({
      ...row,
      columns: row.columns.map(column => ({
        ...column,
        contentBlocks: column.contentBlocks.map(parseContentBlock),
      })),
    }),
  );
};

const parseContentBlock = (contentBlock: ContentBlockData) => {
  return {
    ...contentBlock,
    model: contentBlock.model && JSON.parse(contentBlock.model),
  };
};
