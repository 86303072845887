import styles from '../BasketFooter.module.scss';
import PropTypes from 'prop-types';
import { useCurrencyInfo } from 'utils/hooks';
import PriceNode from '../../PriceNode';
import { useIsPreview } from 'components/objects/preview';

const TotalsLine = ({ label, value, className = null }) => {
  const currencyInfo = useCurrencyInfo();
  const isPreview = useIsPreview();

  return (
    <tr className={className}>
      <th>{label}</th>
      <td className={styles.cellValue}>
        <PriceNode price={isPreview ? null : value} currencyInfo={currencyInfo} className="price-node" />
      </td>
    </tr>
  );
};

TotalsLine.propTypes = {
  label: PropTypes.node,
  value: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default TotalsLine;
