import {
  LASTVIEWED_PRODUCTS_CLEARED,
  LASTVIEWED_PRODUCTS_RECEIVED,
  LASTVIEWED_PRODUCTS_REQUESTED,
  PRODUCT_VIEWED,
} from './actions';
import { VIEWER_CHANGED, LANGUAGE_CHANGED } from 'behavior/events';
import { createReducer } from 'utils/redux';
import { arrayToObject } from 'utils/helpers';

const initialState = {
  products: null,
  expired: false,
};

export default createReducer(initialState, {
  [LASTVIEWED_PRODUCTS_REQUESTED]: onRequested,
  [LASTVIEWED_PRODUCTS_RECEIVED]: onProductsReceived,
  [LASTVIEWED_PRODUCTS_CLEARED]: onCleared,
  [PRODUCT_VIEWED]: onProductViewed,
  [LANGUAGE_CHANGED]: onExpired,
  [VIEWER_CHANGED]: onCleared,
});

function onRequested(state, action) {
  if (action.payload.skipCurrent)
    return state;

  // Moved from PDP to PLP -> clear lastViewed
  return { ...state, lastViewed: null };
}

function onProductsReceived(state, action) {
  const { products: payloadProducts, calculated } = action.payload;
  const stateProducts = state.products;

  // New portion of products arrived
  if (!calculated) {
    return {
      ...state,
      products: payloadProducts,
      expired: false,
    };
  }

  if (stateProducts && stateProducts.length && !state.expired) {
    const productsMap = arrayToObject(payloadProducts, product => product.id);
    return {
      ...state,
      products: stateProducts.map(product => {
        const calculated = productsMap[product.id];

        return calculated
          ? { ...product, ...calculated }
          : product;
      }),
    };
  }

  // Just in case somebody cleared products between calls with calculated==false and calculated==true
  return state;
}

function onExpired(state, _action) {
  return { ...state, expired: true };
}

function onCleared(state, _action) {
  return {
    ...state,
    products: null,
  };
}

function onProductViewed(state, action) {
  const { payload: { id, isParent } } = action; //TICKET 118259 [Marlboro] Product grouping – Last viewed/checkout offers/add-ons
  const { lastViewed } = state;

  if (lastViewed && lastViewed === id)
    return state;

  return {
    ...state,
    products: null,
    lastViewed: id,
  };
}
