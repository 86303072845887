import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { merge } from 'rxjs';
import { switchMap, pluck, map, withLatestFrom } from 'rxjs/operators';
import { retryWithToast } from 'behavior/errorHandling';
import { routesBuilder } from 'routes';
import {
  ProductSelectorAction,
  PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED,
  PRODUCT_SELECTOR_PRODUCT_REQUESTED,
  receiveSearchSuggestions,
  receiveProduct,
} from './actions';
import { productSearchSuggestionsQuery, productQuery } from './queries';
import type { Product, Suggestion } from './types';

type ProductSearchSuggestionsResponse = {
  catalog: {
    quickSearch: {
      products: Suggestion[];
    };
  };
};

type ProductQueryResponse = {
  catalog: {
    products: {
      products: Product[];
    };
  };
};

const epic: Epic<ProductSelectorAction> = (action$, state$, { api, logger }) => {
  const searchProducts$ = action$.pipe(
    ofType(PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED),
    pluck('payload'),
    switchMap(({ keywords, count }) => api.graphApi<ProductSearchSuggestionsResponse>(productSearchSuggestionsQuery, { options: { keywords, count } }).pipe(
      pluck('catalog', 'quickSearch', 'products'),
      map(receiveSearchSuggestions),
      retryWithToast(action$, logger),
    )),
  );

  const requestProduct$ = action$.pipe(
    ofType(PRODUCT_SELECTOR_PRODUCT_REQUESTED),
    pluck('payload'),
    withLatestFrom(state$),
    map(([{ id }, { analytics }]) => ({
      options: { ids: [id],
      notGroupRequest:true },
        loadCategories: analytics && analytics.isTrackingEnabled,
    })),
    switchMap(params => api.graphApi<ProductQueryResponse>(productQuery, params).pipe(
      pluck('catalog', 'products', 'products'),
      map(products => {
        const product = products[0];
        product.routeData = routesBuilder.forProduct(product.id);
        return receiveProduct(product);
      }),
      retryWithToast(action$, logger),
    )),
  );

  return merge(searchProducts$, requestProduct$);
};

export default epic;
