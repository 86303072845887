import type { ProductCategoryCollection, Product } from './types';
import { SearchSuggestionsAction, SEARCH_PRODUCT_SUGGESTIONS, productsSearchSuggestionsCompleted } from './actions';
import { ofType } from 'redux-observable';
import { switchMap, map, pluck } from 'rxjs/operators';
import { searchSuggestionsQuery } from './queries';
import { routesBuilder } from 'routes';
import { retryWithToast } from 'behavior/errorHandling';
import { Epic } from 'behavior/types';

type ResponseProduct = {
  id: string;
  title?: string;
  url?: string;
  categoriesPaths: ProductCategoryCollection[];
  image?: {
    small?: string;
    };

  //TICKET 118261-3.11.�Display HHT code:::SUGGESTION
  hhtCode?: string;
};

type SearchSuggestionsResponse = {
  catalog: {
    products: {
      products: ResponseProduct[];
    };
  };
};

const epic: Epic<SearchSuggestionsAction> = (action$, _, { api, logger }) => {
  return action$.pipe(
    ofType(SEARCH_PRODUCT_SUGGESTIONS),
    pluck('payload'),
    switchMap(options =>
      api.graphApi<SearchSuggestionsResponse>(searchSuggestionsQuery, { options }).pipe(
        map(mapSuggestions),
        retryWithToast(action$, logger),
      ),
    ),
  );
};

export default epic;

export function mapSuggestions(data: SearchSuggestionsResponse) {
  const suggestedProducts: Product[] = [];

  for (const product of data.catalog.products.products) {
    const suggestedProduct: Product = {
      ...product,
      routeData: routesBuilder.forProduct(product.id),
      imageUrl: product.image && product.image.small,
    };

    delete suggestedProduct.image;

    suggestedProducts.push(suggestedProduct);
  }

  return productsSearchSuggestionsCompleted(suggestedProducts);
}
