import { mergeReducers } from 'utils/redux';
import adminTextsReducer from 'behavior/adminTexts/reducer';
import appReducer from 'behavior/app/reducer';
import ariaStatusReducer from 'behavior/ariaStatus/reducer';
import settingsReducer from 'behavior/settings/reducer';
import routingReducer from 'behavior/routing/reducer';
import sanaTextReducer from 'behavior/sanaText/reducer';
import userReducer from 'behavior/user/reducer';
import navigationReducer from 'behavior/navigation/reducer';
import newsHeadlinesReducer from 'behavior/newsHeadlines/reducer';
import newsReducer from 'behavior/pages/news/reducer';
import changePasswordReducer from 'behavior/pages/changePassword/reducer';
import newsletterReducer from 'behavior/newsletter/reducer';
import pageGenericReducer from 'behavior/pages/reducer';
import contactUsReducer from 'behavior/pages/contactUs/reducer';
import captchaReducer from 'behavior/captcha/reducer';
import localizationReducer from 'behavior/localization/reducer';
import themeReducer from 'behavior/theme/reducer';
import searchReducer from 'behavior/products/search/suggestions/reducer';
import visualDesignerReducer from 'behavior/visualDesigner/reducer';
import insiteEditorReducer from 'behavior/insiteEditor/reducer';
import visualDesignerPageReducer from 'behavior/pages/visualDesigner/pageReducer';
import productReducer from 'behavior/pages/product/reducer';
import representationPageReducer from 'behavior/pages/representation/reducer';
import connectionReducer from 'behavior/connection/reducer';
import basketReducer from 'behavior/basket/reducer';
import childMenuItemsReducer from 'behavior/content/childMenuItems/reducer';
import productListReducer from 'behavior/pages/productList/reducer';
import basketPageReducer from 'behavior/pages/basket/reducer';
import sortableFieldsReducer from 'behavior/products/search/sortableFields/reducer';
import lastViewedPageReducer from 'behavior/pages/productList/lastViewed/reducer';
import lastViewedReducer from 'behavior/products/lastViewedTracking/reducer';
import orderTemplatesReducer from 'behavior/pages/orderTemplates/reducer';
import salesAgreementsPageReducer from 'behavior/pages/salesAgreements/reducer';
import productConfiguratorReducer from 'behavior/productConfigurator/reducer';
import loadingIndicatorReducer from 'behavior/loadingIndicator/reducer';
import documentsReducer from 'behavior/pages/documents/reducer';
import productComparisonReducer from 'behavior/productComparison/reducer';
import documentReducer from 'behavior/pages/document/reducer';
import checkoutOffersReducer from 'behavior/checkoutOffers/reducer';
import forgotPasswordReducer from 'behavior/pages/forgotPassword/reducer';
import resetPasswordReducer from 'behavior/pages/resetPassword/reducer';
import registrationReducer from 'behavior/pages/registration/reducer';
import createProspectReducer from 'behavior/pages/createProspect/reducer';
import profilerReducer from 'behavior/tools/profiler/reducer';
import subAccountsReducer from 'behavior/pages/subAccounts/reducer';
import productComparisonPageReducer from 'behavior/pages/productComparison/reducer';
import productSetsReducer from 'behavior/content/productSets/reducer';
import checkoutReducer from 'behavior/pages/checkout/reducer';
import analyticsReducer from 'behavior/analytics/reducer';
import orderAuthorizationsReducer from 'behavior/pages/orderAuthorizations/reducer';
import invoicePaymentReducer from 'behavior/pages/invoicePayment/reducer';
import productSelectorReducer from 'behavior/productSelector/reducer';
import createDocFreeReturnOrderReducer from 'behavior/pages/createDocFreeReturnOrder/reducer';
import createDocBasedReturnOrderReducer from 'behavior/pages/createDocBasedReturnOrder/reducer';
import wishListReducer from 'behavior/wishList/reducer';
import wishListPageReducer from 'behavior/pages/wishList/reducer';
import errorReducer from 'behavior/errorHandling/reducer';
import orderPaymentReducer from 'behavior/pages/orderPayment/reducer';
import headerReducer from 'behavior/header/reducer';

//TICKET-118265:3.15.�Outstanding balance � My account dashboard:::DASHBOARD
import outstandingsReducer from 'behavior/pages/outstandings/reducer';


const pageReducer = mergeReducers(
  pageGenericReducer,
  newsReducer,
  changePasswordReducer,
  contactUsReducer,
  visualDesignerPageReducer,
  productReducer,
  productListReducer,
  basketPageReducer,
  representationPageReducer,
  childMenuItemsReducer,
  lastViewedPageReducer,
  documentsReducer,
  documentReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  registrationReducer,
  createProspectReducer,
  salesAgreementsPageReducer,
  subAccountsReducer,
  productComparisonPageReducer,
  productSetsReducer,
  checkoutReducer,
  orderAuthorizationsReducer,
  invoicePaymentReducer,
  createDocFreeReturnOrderReducer,
  createDocBasedReturnOrderReducer,
  orderPaymentReducer,
  wishListPageReducer,
  productConfiguratorReducer,
  outstandingsReducer
);

export default {
  adminTexts: adminTextsReducer,
  app: appReducer,
  ariaStatus: ariaStatusReducer,
  basket: basketReducer,
  captcha: captchaReducer,
  checkoutOffers: checkoutOffersReducer,
  connection: connectionReducer,
  error: errorReducer,
  isLoading: loadingIndicatorReducer,
  lastViewed: lastViewedReducer,
  localization: localizationReducer,
  navigation: navigationReducer,
  newsHeadlines: newsHeadlinesReducer,
  newsletter: newsletterReducer,
  orderTemplates: orderTemplatesReducer,
  page: pageReducer,
  productComparison: productComparisonReducer,
  productSelector: productSelectorReducer,
  profiler: profilerReducer,
  routing: routingReducer,
  sanaTexts: sanaTextReducer,
  settings: settingsReducer,
  sortableFields: sortableFieldsReducer,
  suggestions: searchReducer,
  theme: themeReducer,
  user: userReducer,
  visualDesigner: visualDesignerReducer,
  insiteEditor: insiteEditorReducer,
  analytics: analyticsReducer,
  wishList: wishListReducer,
  header: headerReducer,
};
