exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BrandImage_image{width:120px;float:none;margin:3px;padding:3px}", ""]);

// exports
exports.locals = {
	"image": "BrandImage_image"
};