import styles from './ProductComparisonRating.module.scss';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import { RowRails } from '../swiper';
import RatingCell from './RatingCell';
import { ProductTrackingContext } from 'components/objects/analytics';
import { EventSource } from 'behavior/analytics';
import { StarsRating } from 'components/primitives/rating';
import ReviewsLink from './ReviewsLink';
import { useIsPreview } from 'components/objects/preview';

const RatingRow = ({ products }) => {
  const ratingsAndReviewsEnabled = useSelector(({ settings }) => settings.product.ratingsAndReviewsEnabled);
  const isPreview = useIsPreview();

  if (!ratingsAndReviewsEnabled)
    return null;

  return (
    <>
      <h2 className={styles.title}><SimpleText textKey="ProductCompare_Reviews" /></h2>
      <RowRails items={products} highlightColumns>
        {product => isPreview
          ? (
            <div className={styles.fieldValue}>
              <StarsRating value={0} />
              <ReviewsLink reviewsTotal={0} />
            </div>
          )
          : (
            <ProductTrackingContext.Provider value={{ product, trackingSource: EventSource.CompareProductsPage }}>
              <RatingCell product={product} />
            </ProductTrackingContext.Provider>
          )
        }
      </RowRails>
    </>
  );
};

RatingRow.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({ reviews: PropTypes.object }).isRequired,
  ),
};

export default RatingRow;