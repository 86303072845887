//TICKET-118265:3.15. Outstanding balance – My account dashboard:::DASHBOARD
//TICKET-118266:3.16. Outstanding balance – Overview page:::PAGE
import styles from '../Orders.module.scss';
import PropTypes from 'prop-types';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { connect } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import { Table } from 'components/primitives/table';
import { toLocaleDate } from 'utils/format';
import { FormattedPrice } from 'components/primitives/price';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsPreview } from 'components/objects/preview';

const OutstandingsList = ({
  outstandings,
  culture,
  previewDocumentsCount,
}) => {
  const [showPrices] = useHasAbilities(AbilityTo.ViewPrices);
  const isPreview = useIsPreview();

  return (
    <Table className={styles.ordersList}>
      <thead>
        <tr>
          <th id="headerPostingDate" className={styles.documentIdCell}><SimpleText textKey="Outstandings_PostingDate" /></th>
          <th id="headerDocumentType" className={styles.dateCell}><SimpleText textKey="Outstandings_DocumentType" /></th>
          <th id="headerDocumentNumber" className={styles.dateCell}><SimpleText textKey="Outstandings_DocumentNumber" /></th>
          {showPrices && <th id="headerTotal" className={styles.priceCell}><SimpleText textKey="Outstandings_Total" /></th>}
        </tr>
      </thead>
      <tbody>
        {!isPreview
          ? outstandings.map((order, index) => {
            const {
              postingDate,
              documentType,
              documentNumber,
              currency,
              outstandingAmount,
            } = order;

            return (
              <tr key={index}>
                <th scope="row" aria-labelledby="headerPostingDate">{toLocaleDate(postingDate, culture)}</th>
                <td aria-labelledby="headerDocumentType">{documentType}</td>
                <td aria-labelledby="headerDocumentNumber">{documentNumber}</td>
                {showPrices &&
                  <td aria-labelledby="headerTotal" className={styles.total}>
                  <FormattedPrice price={outstandingAmount} currencyInfo={currency} />
                  </td>
                }
              </tr>
            );
          })
          : Array.from(Array(previewDocumentsCount || 5)).map((_, index) => (
            <tr key={index}>
              <th scope="row"><Placeholder /></th>
              <td><Placeholder /></td>
              <td><Placeholder /></td>
              {showPrices && <td className={styles.total}><Placeholder /></td>}
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
};

OutstandingsList.propTypes = {
  outstandings: PropTypes.arrayOf(PropTypes.shape({
    postingDate: PropTypes.string.isRequired,
    documentType: PropTypes.string.isRequired,
    documentNumber: PropTypes.string.isRequired,
    currency: PropTypes.object,
    outstandingAmount: PropTypes.number,
  })),
  culture: PropTypes.string,
  previewDocumentsCount: PropTypes.number,
};

const mapStateToProps = ({
  localization: { currentLanguage },
}) => ({
  culture: currentLanguage && currentLanguage.cultureName,
});

export default connect(mapStateToProps)(OutstandingsList);
