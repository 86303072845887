import type { SystemPage, SystemPageData } from 'behavior/pages/system';
import type { Handler } from '../types';
import type { LoadNewsResponse, NewsItem } from './types';
import { loadNewsPageQuery } from './queries';
import { PageComponentNames } from '../componentNames';
import { map } from 'rxjs/operators';
import { initSystemPageContent } from 'behavior/pages/system';
import { RouteName } from 'routes';

type NewsRouteData = {
  routeName: RouteName.NewsOverview;
};

type NewsOverviewPage = SystemPage & {
  component: PageComponentNames.News;
  news: NewsItem[];
};

type InitiatedPage = {
  page: SystemPageData & {
    component: PageComponentNames.News;
    news: NewsItem[];
  };
};

const handler: Handler<NewsRouteData, NewsOverviewPage> = (_routeData, _state$, { api }) => api.graphApi(loadNewsPageQuery).pipe(
  map<LoadNewsResponse, InitiatedPage>(({ pages, news }) => {
    const result = {
      page: pages.news,
    } as InitiatedPage;

    result.page.news = news.list.items;
    result.page.component = PageComponentNames.News;

    return result;
  }),
  initSystemPageContent<InitiatedPage, { page: NewsOverviewPage }>(),
);

export default handler;
