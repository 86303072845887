import styles from './OrderTemplates.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { routesBuilder } from 'routes';
import LineTemplate from './LineTemplate';
import { useFormatNumberWithCulture } from 'utils/hooks';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking, ProductTrackingContext } from 'components/objects/analytics';
import { EventSource } from 'behavior/analytics';

const Line = ({ line, showUom }) => {
  const { product, subLines, hasConfiguration } = line;
  const areVariantLines = typeof subLines[0].title !== 'undefined';
  const formatNumber = useFormatNumberWithCulture();

  const productTitle = hasConfiguration
    ? <span className={styles.productTitle}>{product.title}</span>
    : (
      <ProductTrackingContext.Provider value={createTrackingData(product, subLines)}>
        <WithProductClickTracking>
          {handleClick => (
            <Link
              onClick={handleClick}
              url={product.url}
              to={routesBuilder.forProduct(product.id)}
              className={styles.productTitle}
            >
              {product.title}
            </Link>
          )}
        </WithProductClickTracking>
      </ProductTrackingContext.Provider>
    );

  //TICKET 118261-3.11.�Display HHT code:::ORDERTEMPLATES
  return (
    <>
      {areVariantLines &&
        <LineTemplate
          className={`${styles.tableRow} ${styles.upperRow}`}
          idCell={product.id}
          hhtCode={product.hhtCode}
          titleCell={productTitle}
          uom={showUom ? null : undefined}
        />
      }
      {subLines.map((subLine, index) => {
        const uom = showUom ? findUomOrDefault(product.uoms, subLine.uomId) : undefined;
        return (
          <LineTemplate
            key={index}
            className={`${styles.tableRow} ${areVariantLines ? styles.innerRow : styles.upperRow}`}
            idCell={areVariantLines ? null : product.id}
            hhtCode={areVariantLines ? null : product.hhtCode}
            titleCell={areVariantLines ? subLine.title : productTitle}
            quantityCell={formatNumber(subLine.quantity)}
            uom={uom}
          />
        );
      })}
    </>
  );
};

Line.propTypes = {
  line: PropTypes.shape({
    product: PropTypes.shape({
      id: PropTypes.string,
      hhtCode: PropTypes.string,
      title: PropTypes.string,
      uoms: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
      })),
      url: PropTypes.string,
    }),
    hasConfiguration: PropTypes.bool,
    subLines: PropTypes.arrayOf(PropTypes.shape({
      uomId: PropTypes.string,
      quantity: PropTypes.number,
      title: PropTypes.string,
    })),
  }),
  showUom: PropTypes.bool,
};

export default memo(Line);

function findUomOrDefault(uoms, id) {
  return uoms && uoms.find(u => u.id === id) || { id };
}

function createTrackingData(product, subLines) {
  return {
    product: {
      ...product,
      uomId: findUomIdToTrack(product, subLines),
    },
    trackingSource: EventSource.OrderTemplate,
  };
}

function findUomIdToTrack(product, subLines) {
  if (subLines.length > 1)
    return undefined;

  const uom = findUomOrDefault(product.uoms, subLines[0].uomId);
  return uom.id;
}