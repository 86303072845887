import { memo } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import Specifications from './Specifications';
import Attachments from './attachments';
import { renderHTML } from 'utils/render';
import { Tabs } from 'components/objects/tabs';
import { ReviewTab } from '../reviews';

const ProductTabs = ({
  specifications,
  description,
  attachments,
  reviews,
  productId,
}) => {
  const showDescription = description && !!description.length;
  const showSpecifications = specifications && !!specifications.length;
  const showAttachments = attachments && !!attachments.length;

  let reviewsTab = null;
  if (reviews) {
    const title = (
      <SimpleText
        textKey="Product_ReviewsAmountHeader"
        formatWith={[reviews.total]}
      />
    );

    reviewsTab = (
      <Tabs.Item tabKey="reviews" title={title}>
        <ReviewTab
          reviews={reviews.list}
          total={reviews.total}
          productId={productId}
        />
      </Tabs.Item>
    );
  }
  return (
    <Tabs name="pdp" resetDependency={productId}>
      {showSpecifications && (
        <Tabs.Item
          tabKey="specifications"
          title={<SimpleText textKey="Product_TabSpecifications" />}
        >
          <Specifications specifications={specifications} />
        </Tabs.Item>
      )}
      {showDescription && (
        <Tabs.Item
          tabKey="description"
          title={<SimpleText textKey="Description" />}
        >
          <div className="description fr-view">{renderHTML(description)}</div>
        </Tabs.Item>
      )}      
      {showAttachments && (
        <Tabs.Item
          tabKey="attachments"
          title={<SimpleText textKey="Product_Attachments" />}
        >
          <Attachments attachments={attachments} />
        </Tabs.Item>
      )}
      {reviewsTab}
    </Tabs>
  );
};

ProductTabs.propTypes = {  
  specifications: PropTypes.array,
  description: PropTypes.string,
  attachments: PropTypes.array,
  productId: PropTypes.string,
  reviews: PropTypes.shape({
    total: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
  }),
};

export default memo(ProductTabs);