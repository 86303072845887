import { memo } from 'react';
import PropTypes from 'prop-types';
import RecentOrders from './RecentOrders';
import RecentOutstandingBalance from './RecentOutstandingBalance';
import MainBlock from './MainBlock';
import AccountManagement from './AccountManagement';

const MyAccount = ({ orders, outstandings }) => (

  <>
    <MainBlock />

    {/*TICKET-118265:3.15.�Outstanding balance � My account dashboard:::DASHBOARD*/}
    <RecentOutstandingBalance outstandings={outstandings?.items} balance={outstandings?.balance} />
    <RecentOrders orders={orders} />
    
    <AccountManagement />
  </>
);

MyAccount.propTypes = {
  orders: PropTypes.array,
  outstandings: PropTypes.object
};

const Memoized = memo(MyAccount);

Memoized.selectPropsFromPage = function ({ docs, outstandings }) {
  return {
    orders: docs && docs.items,
    outstandings: outstandings ? { items: outstandings.items, balance: outstandings.balance } : undefined
  };
};

export default Memoized;