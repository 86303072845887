import { default as createHandler } from './handler';
import {
  outstandingsPageQuery
} from './queries';
import { PageComponentNames } from '../componentNames';

//TICKET 118265:3.15. Outstanding balance – My account dashboard:::DASHBOARD
export { requestOutstandingBalance } from './actions';

//TICKET-118266:3.16. Outstanding balance – Overview page:::PAGE
export { normalizeFilter, createOptions } from './handler';
export const outstandingsHandler = createHandler(PageComponentNames.Outstandings, outstandingsPageQuery);