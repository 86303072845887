import styles from './MyAccount.module.scss';
import { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHasAbilities } from 'components/objects/user';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import { withAbilities } from 'components/objects/user';
import { SimpleText, RichText, UseSanaTexts } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { InfoAlert } from 'components/primitives/alerts';
import { routesBuilder } from 'routes';
import { abilitiesPropType } from 'behavior/user';
import { requestOutstandingBalance } from 'behavior/pages/outstandings';
import { OutstandingsList } from '../documents';
import { useDispatch } from 'react-redux';
import Spinner from 'components/primitives/spinner/Spinner';
import { useIsPreview } from 'components/objects/preview';
import { FormattedPrice } from 'components/primitives/price';

const pageSize = 3;

//TICKET-118265:3.15. Outstanding balance – My account dashboard:::DASHBOARD
const RecentOutstandingBalance = ({ abilities: [viewOrdersAbility], outstandings, balance }) => {
  const dispatch = useDispatch();
  const viewAllOptions = useMemo(() => ({ backTo: { routeData: routesBuilder.forMyAccount() } }), []);
  const isPreview = useIsPreview();

  useEffect(() => {
    if (viewOrdersAbility !== AbilityState.Available || outstandings || isPreview)
      return;

    const from = new Date();
    from.setMonth(from.getMonth() - 3);

    const options = {
      page: {
        index: 0,
        size: pageSize,
      },
      recent: 1
    };

    dispatch(requestOutstandingBalance(options));

  }, [viewOrdersAbility, outstandings, isPreview]);

  const showOrders = (outstandings && !!outstandings.length) || isPreview;
  const [showPrices] = useHasAbilities(AbilityTo.ViewPrices);
  const currency = outstandings && outstandings.length ? outstandings[0].currency : '';

  const titleClass = styles.title + ' h3';
  if (viewOrdersAbility === AbilityState.TemporaryNotAvailable)
    return (
      <div className={styles.orderInfo}>
        <div className={styles.heading}>
          <h2 className={titleClass}><SimpleText textKey="RecentOutstandingTransactions" /></h2>
        </div>
        <InfoAlert>
          <RichText textKey="OutstandingTransactionsNotAvailable" />
        </InfoAlert>
      </div>
    );

  if (!viewOrdersAbility)
    return <UseSanaTexts options={['RecentOutstandingTransactions', 'ViewAll', 'Orders_NoOutstandingTransactions']} />;

  return (
    <div className={styles.orderInfo}>
      {viewOrdersAbility === AbilityState.Available &&
        <>
        {showPrices && outstandings && outstandings.length && <div className={"outStading"} ><strong> <SimpleText textKey="OutstandingBalance" /> <FormattedPrice price={balance} currencyInfo={currency} /></strong></div>}
        <div className={styles.heading}>
          <h2 className={titleClass}><SimpleText textKey="RecentOutstandingTransactions" /></h2>
          {showOrders &&
            <Link to={routesBuilder.forOutstandings} options={viewAllOptions}>
                <SimpleText textKey="ViewAll" />
              </Link>
            }
          {(!outstandings && !isPreview) && <Spinner className={styles.spinner} />}
          </div>
          {showOrders && <OutstandingsList outstandings={outstandings} previewDocumentsCount={pageSize} />}
          {outstandings && !outstandings.length &&
            <div className="msg-block">
              <SimpleText textKey="Orders_NoOutstandingTransactions" />
            </div>
          }
        </>
      }
      
    </div>
  );
};

RecentOutstandingBalance.propTypes = {
  abilities: abilitiesPropType,
  orders: PropTypes.array,
};

export default withAbilities(RecentOutstandingBalance, [AbilityTo.ViewOrders]);