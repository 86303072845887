import { OUTSTANDINGBALANCE_RECEIVED } from './actions';
import { createReducer } from 'utils/redux';

export default createReducer(null, {
  [OUTSTANDINGBALANCE_RECEIVED]: onOutstandingBalanceReceived
});


function onOutstandingBalanceReceived(state, action) {
  const {
    documents,
    page
  } = action.payload;

  //TICKET 118265:3.15. Outstanding balance – My account dashboard:::DASHBOARD
  if (!state.outstandings || page === 0)
    return {
      ...state,
      outstandings: {
        items: documents.items,
        balance: documents.balance,
        totalCount: documents.totalCount
      }
    };

  //TICKET-118266:3.16. Outstanding balance – Overview page:::PAGE
  const { items, balance, totalCount } = documents;

  return {
    ...state,
    outstandings: {
      items: state.outstandings.items.concat(items),
      balance,
      totalCount,
    }
  };
}
