import styles from './OrderTemplates.module.scss';
import tableStyles from 'components/primitives/table/Table.module.scss';
import { memo, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox } from 'components/primitives/form';
import { VerticalSliding } from 'components/primitives/transitions';
import { UseSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { useAbilities } from 'components/objects/user';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { loadOrderTemplateLines } from 'behavior/pages/orderTemplates/actions';
import Line from './Line';
import Spinner from 'components/primitives/spinner/Spinner';
import { toLocaleDate } from 'utils/format';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsPreview } from 'components/objects/preview';

//TICKET 118261-3.11.�Display HHT code:::ORDERTEMPLATES
const preloadSanaTexts = [
  'General_Product_Id',
  'General_Product_Title',
  'General_Product_Quantity',
  'UOM',
  'OrderTemplates_Removed',
  'MyAccount_OrderTemplate_NotAvailableTemplateText',
  'HHT_Code_Header',
];

const Item = ({ orderTemplate, selected, toggle, culture }) => {
  const orderTemplateId = orderTemplate.id;
  const dispatch = useDispatch();
  const [viewUomAbility] = useAbilities([AbilityTo.ViewUnitOfMeasure]).abilities;
  const showUom = viewUomAbility === AbilityState.Available;
  const [expanded, setExpanded] = useState(false);
  const [rowDisplayed, displayDetailsRow] = useState(false);
  const rowRef = useRef(null);
  const isPreview = useIsPreview();

  const loaded = orderTemplate.lines !== undefined;
  const expandedAndLoaded = expanded && loaded;

  const onClick = () => setExpanded(!expanded);

  useEffect(() => {
    if (!expanded || loaded)
      return;

    dispatch(loadOrderTemplateLines(orderTemplate.id));
  }, [expanded, loaded]);

  const hasLines = orderTemplate.lines && orderTemplate.lines.length > 0;
  const orderTemplateCellId = `Template_${orderTemplateId}`;

  return (
    <tbody aria-labelledby={orderTemplateCellId}>
      <tr
        ref={rowRef}
        className={`${styles.templateItem} ${expandedAndLoaded ? styles.expanded : ''}`}
      >
        <td className={styles.checkboxCol}>
          <UseSanaTexts options={['Select', 'Deselect']}>
            {([selectText, deselectText]) => {
              return (
                <Checkbox
                  onChange={_e => toggle(orderTemplateId)}
                  checked={selected}
                  title={makeSimpleText(selected ? deselectText : selectText)}
                />
              );
            }}
          </UseSanaTexts>
        </td>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
        <td id={orderTemplateCellId} onClick={onClick} className={styles.templateName}>
          {isPreview ? <Placeholder /> : orderTemplate.name}
        </td>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
        <td onClick={onClick} className={styles.date}>
          {isPreview
            ? <Placeholder />
            : (
              <div className={styles.dateWrapper}>
                {toLocaleDate(orderTemplate.createdDate, culture)}
                {expanded && !loaded && <Spinner className={styles.loader} />}
              </div>
            )
          }
        </td>
        <td>
          <button type="button" className={styles.expandButton} onClick={onClick} />
        </td>
      </tr>
      <tr className={`${styles.detailsRow} ${(!(expanded && !!orderTemplate.lines) && !rowDisplayed) ? styles.hidden : ''}`}>
        {hasLines && <td aria-hidden className={styles.detailsGutter} />}
        <td colSpan={!hasLines ? '4' : '3'}>
          <VerticalSliding
            onEnter={() => displayDetailsRow(true)}
            onExited={() => displayDetailsRow(false)}
            expanded={expandedAndLoaded}
          >
            <UseSanaTexts options={preloadSanaTexts} dontWait>
              {/*TICKET 118261-3.11.�Display HHT code:::ORDERTEMPLATES*/}
              {([idText, titleText, quantityText, uomText, noTemplateText, emptyLinesText, hhtCodeText]) => {
                if (hasLines)
                  return (
                    <table className={tableStyles.table}>
                      <thead>
                        <tr>
                          {/*TICKET 118261-3.11.�Display HHT code:::ORDERTEMPLATES*/}
                          <th className={styles.headerId}>{makeSimpleText(hhtCodeText)}</th>
                          <th className={styles.headerTitle}>{makeSimpleText(titleText)}</th>
                          <th className={`${styles.headerQuantity} text-center`}>{makeSimpleText(quantityText)}</th>
                          {showUom && <th className={`${styles.headerUom} text-right`}>{makeSimpleText(uomText)}</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {orderTemplate.lines.map((line, index) => <Line key={index} line={line} showUom={showUom} />)}
                      </tbody>
                    </table>
                  );

                if (orderTemplate.lines === null)
                  return noLinesMsg(noTemplateText);

                if (orderTemplate.lines && orderTemplate.lines.length === 0)
                  return noLinesMsg(emptyLinesText);

                return null;
              }}
            </UseSanaTexts>
          </VerticalSliding>
        </td>
      </tr>
    </tbody>
  );
};

Item.propTypes = {
  orderTemplate: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  culture: PropTypes.string,
};

export default memo(Item);

// eslint-disable-next-line react/no-multi-comp
const noLinesMsg = text => (
  <div className={`msg-block ${styles.noLines}`}>
    {makeSimpleText(text)}
  </div>
);
