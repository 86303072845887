import styles from './PromotionalBanner.module.scss';
import { memo } from 'react';

//TICKET 158383 - Promotional Banner (Best Practices)
const PromotionalBanner = ({ promotionalBanner, imageSize }) => {

  if (!imageSize)
    imageSize = 'medium';

  const imagePath = `/product/image/${imageSize}/${promotionalBanner}.png`;
  if (!promotionalBanner)
    return null;
  return (
    <div className="promotional-banner-wrapper">
      <img className={styles.image} src={imagePath} alt={promotionalBanner} />
    </div>
  );
};

export default memo(PromotionalBanner);