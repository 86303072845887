export const LASTVIEWED_PRODUCTS_CLEARED = 'LASTVIEWED_PRODUCTS_CLEARED';
export const clearLastViewedProducts = () => ({
  type: LASTVIEWED_PRODUCTS_CLEARED,
});

export const LASTVIEWED_PRODUCTS_REQUESTED = 'LASTVIEWED_PRODUCTS_REQUESTED';
export const requestLastViewedProducts = (skipCurrent, count) => ({
  type: LASTVIEWED_PRODUCTS_REQUESTED,
  payload: { skipCurrent, count },
});

export const LASTVIEWED_PRODUCTS_RECEIVED = 'LASTVIEWED_PRODUCTS_RECEIVED';
export const lastViewedProductsReceived = (products, calculated = false) => ({
  type: LASTVIEWED_PRODUCTS_RECEIVED,
  payload: { products, calculated },
});

//TICKET 118259[Marlboro] Product grouping – Last viewed / checkout offers / add - ons
export const PRODUCT_VIEWED = 'PRODUCT_VIEWED';
export const trackViewedProduct = (id, isParent = false) => ({
  type: PRODUCT_VIEWED,
  payload: { id, isParent }, 
});