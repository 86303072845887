import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isAbsoluteUrl, encodeSpacesInUrl } from 'utils/url';
import { getBreakpointsObservable, getBreakpointPath } from './helpers';
import { breakpoints, DEFAULT_BREAKPOINT, respPathRegex, exceptionsRegex } from './constants';
import { omit } from 'lodash';
import LazyLoadImage from './LazyLoadImage';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import PromotionalBanner from './PromotionalBanner';

const breakpointsObservable = getBreakpointsObservable(breakpoints);

const lastWidth = breakpoints[breakpoints.length - 1];

const ResponsiveLazyImage = ({ src, alt, title, placeholderClassName, promotionalBanner, ...attributes }) => {
  src = encodeSpacesInUrl(src);

  const imageShouldBeProcessed = !isAbsoluteUrl(src) && !exceptionsRegex.test(src);
  const staticWidth = attributes.style && attributes.style.width && attributes.style.width.indexOf('px') > -1
    ? parseInt(attributes.style.width, 10)
    : null;
  const isWidthStatic = staticWidth !== null;
  let staticWidthSrc = null;

  if (imageShouldBeProcessed && isWidthStatic) {
    staticWidthSrc = src.replace(respPathRegex, `/images/${staticWidth}/`);
    src = staticWidthSrc;
  }

  const [srcSetAttrs, setSrcSetAttrs] = useState(
    generateInitialState.bind({ imageShouldBeProcessed, isWidthStatic, staticWidthSrc, staticWidth, src }),
  );

  useEffect(() => {
    if (!imageShouldBeProcessed) {
      setSrcSetAttrs(null);
      return;
    }

    const { devicePixelRatio } = window;
    const breakpointsSubscription = breakpointsObservable.subscribe(matchedBreakpoint => {
      const isDefaultBreakpointMatched = +matchedBreakpoint === DEFAULT_BREAKPOINT;

      if (
        isWidthStatic &&
        (staticWidth < matchedBreakpoint || (isDefaultBreakpointMatched && staticWidth > lastWidth))
      ) {
        setSrcSetAttrs({ srcSet: `${staticWidthSrc} ${staticWidth}w`, sizes: `${staticWidth}px` });
        return;
      }

      const pointSrc = isDefaultBreakpointMatched ? src : src.replace(respPathRegex, getBreakpointPath(matchedBreakpoint));
      const imageWidth = Math.floor(matchedBreakpoint * devicePixelRatio);
      setSrcSetAttrs({ srcSet: `${pointSrc} ${imageWidth}w`, sizes: `${imageWidth}px` });
    });

    return () => {
      breakpointsSubscription.unsubscribe();
    };
  }, [imageShouldBeProcessed, isWidthStatic, src]);

  const placeholder = (
    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
      className={placeholderClassName} alt={alt} title={title} style={getPlaceholderStyles(attributes.style)}
    />
  );

  return (
    <>
      {/*TICKET 158383 - Promotional Banner (Best Practices)*/}
      <PromotionalBanner promotionalBanner={promotionalBanner} />
      <LazyLoadImage placeholder={placeholder} effect="opacity" {...srcSetAttrs}
        src={src} alt={alt} title={title} {...attributes}
      />
      <noscript><img src={src} alt={alt} title={title} {...getImageAttributes(attributes)} /></noscript>
    </>
  );
};

export const LazyImage = ({ src, alt, title, placeholderClassName, promotionalBanner, promotionalBannerSize, ...attributes }) => {

  {/*TICKET 158383 - Promotional Banner (Best Practices)*/ }
  let imageSize = 'small';
  if (src.startsWith('/product/image/medium'))
    imageSize = 'medium';

  if (promotionalBannerSize)
    imageSize = promotionalBannerSize;

 /* console.log(src, "test")*/
  src = encodeSpacesInUrl(src);

  const placeholder = (
    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
      className={placeholderClassName} alt={alt} title={title} style={getPlaceholderStyles(attributes.style)}
    />
  );

  return (
    <>
      {/*TICKET 158383 - Promotional Banner (Best Practices)*/}
      <PromotionalBanner promotionalBanner={promotionalBanner} imageSize={imageSize} />
      <LazyLoadImage alt={alt} title={title} src={src} effect="opacity" placeholder={placeholder} {...attributes} />
      <noscript><img alt={alt} title={title} src={src} {...getImageAttributes(attributes)} /></noscript>
    </>
  );
};

const imagePropTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
  placeholderClassName: PropTypes.string,
};

ResponsiveLazyImage.propTypes = imagePropTypes;
LazyImage.propTypes = imagePropTypes;

export default memo(ResponsiveLazyImage);

function getImageAttributes(attributes) {
  return omit(attributes, [
    'afterLoad',
    'beforeLoad',
    'delayMethod',
    'delayTime',
    'effect',
    'placeholder',
    'placeholderSrc',
    'threshold',
    'visibleByDefault',
    'wrapperClassName',
  ]);
}

function generateInitialState() {
  const { imageShouldBeProcessed, isWidthStatic, staticWidthSrc, staticWidth, src } = this;
  return imageShouldBeProcessed
    ? {
      srcSet: isWidthStatic ? `${staticWidthSrc} ${staticWidth}w` : `${src} ${DEFAULT_BREAKPOINT}w`,
      sizes: isWidthStatic ? `${staticWidth}px` : `${DEFAULT_BREAKPOINT}px`,
    }
    : null;
}

function getPlaceholderStyles(styles) {
  if (!styles)
    return styles;

  const { width, height, ...placeholderStyles } = styles;

  return placeholderStyles;
}