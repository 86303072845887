import { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';
import { useSanaTexts } from 'components/sanaText';
import { useProductContext } from './hooks';

const ParentProductUrl = () => {
  const { product } = useProductContext();
  const { texts: [
    ViewVariants,
  ] } = useSanaTexts([
    'ViewVariants',
  ]);
  if (!product.parentProductUrl.parentUrl)
    return null;

  return (
     <WithProductClickTracking>
      {handleClick => (
       <Link onClick={handleClick} url={product.parentProductUrl.parentUrl} to={product.routeData}>
          {ViewVariants}
        </Link>
      )}
    </WithProductClickTracking>
  );
};

ParentProductUrl.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string, 
  title: PropTypes.string,
};

export default memo(ParentProductUrl);