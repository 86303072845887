import styles from './Details.module.scss';
import { memo } from 'react';
import { Col, Container, Row } from 'components/primitives/grid';
import OrderBox from './OrderBox';
import ProductProvider from './ProductProvider';
import ProductDescription from './ProductDescription';
import ProductTitle from './ProductTitle';
import ProductsTabsContainer from './ProductsTabsContainer';
import { SocialNetworks } from 'components/objects/socialSharing';
import ProductGallery from './ProductGallery';
import RetailOffers from './RetailOffers';
import { PageContent } from 'components/objects/pages';
import { Totals as ReviewsTotals } from './reviews';
import { usePrintMode } from 'utils/hooks';
import Schema from './Schema';
import PropTypes from 'prop-types';
import { Presets } from 'behavior/pages/product';
import { ProductConfigurator } from 'components/objects/productConfigurator';
import LastViewed from './LastViewed';
import VariantsMatrixPrint from './variantsMatrix/VariantsMatrixPrint';
import ProductComparisonSelector from './ProductComparisonSelector';
import SizeDropdown from './SizeDropdown';
import ParentProductUrl from './ParentProductUrl';

//118263_3_13_PDP_show_brand_image
import BrandImage from './BrandImage';

//TICKET 158383 - Promotional Banner (Best Practices)
import PromotionalBanner from './PromotionalBanner';

const Details = ({ preset, middleContent, footerContent, wishListEnabled }) => {
  const isPrintMode = usePrintMode();
  const matrixPreset = preset === Presets.DetailsWithMatrix;
  const isGroup = new URL(window.location.href).searchParams.get('groupPage') === 'true';

  return (
    <ProductProvider>
      <Container>
        <Row className={`${styles.displayMdBlock} clearfix`}>
          <Col xs={{ size: 12, order: 2 }} md={6}
            className={`${styles.displayMdBlock} ${styles.floatLeft}`}
          >
            {/*TICKET 158383 - Promotional Banner (Best Practices)*/}
            <div className="promotional-wrapper">
              <div className="promotional-banner-wrapper">
                <PromotionalBanner imageSize="large" />
              </div>  
              <ProductGallery />
            </div>
            
          </Col>
          <Col xs={{ size: 12, order: 4 }} md={6}
            className={`${styles.displayMdBlock} ${styles.clearLeft} ${styles.floatLeft}`}
          >
            {!isPrintMode && <SocialNetworks />}
          </Col>
          <Col xs={{ size: 12, order: 1 }} md={6}
            className={`${styles.displayMdBlock} ${styles.customOffset}`}
          >
            <ProductTitle />
            
            {/*//118263_3_13_PDP_show_brand_image*/}
            <BrandImage />            

            {!isGroup && <ParentProductUrl />}
          </Col>
          {!isPrintMode && <ReviewsTotals />}
          <Col xs={{ size: 12, order: 3 }} md={6}
            className={`${styles.displayMdBlock} ${styles.customOffset}`}
          >
            <div className={styles.description}>
              <RetailOffers matrixPreset={matrixPreset} />
              <ProductDescription matrixPreset={matrixPreset} />
            </div>

            {isGroup && <SizeDropdown />}
            <OrderBox matrixPreset={matrixPreset} />
            
            {!isPrintMode && <ProductComparisonSelector className={styles.productComparisonSelector} />}
          </Col>
        </Row>
        {isPrintMode && matrixPreset && <VariantsMatrixPrint />}
      </Container>
      <PageContent content={middleContent} position="middle" />
      <Container>
        <Row className="print-no-flex">
          <ProductsTabsContainer />
        </Row>
      </Container>
      <PageContent content={footerContent} position="footer" />
      {!isPrintMode && <LastViewed />}
      <Schema />
      <ProductConfigurator />
    </ProductProvider>
  );
};

Details.propTypes = {
  preset: PropTypes.string.isRequired,
  middleContent: PropTypes.array,
  footerContent: PropTypes.array,
  wishListEnabled: PropTypes.bool,
};

export default memo(Details);

