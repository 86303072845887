//TICKET-118265:3.15.�Outstanding balance � My account dashboard:::DASHBOARD
import styles from './Orders.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useRef } from 'react';
import PropTypes from 'prop-types';
import { RichText, SimpleText } from 'components/sanaText';
import { LoadMoreButton } from 'components/primitives/buttons';
import { useLoadMore } from './hooks';
import { useOnChange } from 'utils/hooks';
import { useIsPreview, useHandlerLockerInPreview } from 'components/objects/preview';
import { FormattedPrice } from 'components/primitives/price';

const OutstandingBalance = ({
  items,
  totalCount,
  balance,
  size,
  filter,
  loadMoreRoute,
  textKeys,
  actions,
  footer,
  children,
}) => {
  const loadMore = useLoadMore(loadMoreRoute);
  const filterRef = useRef();
  const isPreview = useIsPreview();

  useOnChange(() => {
    filterRef.current = filter;
  }, [filter]);

  return (
    <div className={styles.order}>
      {textKeys.introduction && <div className={styles.introduction}><RichText textKey={textKeys.introduction} /></div>}

      <div className={styles.recentOrders}>
        {items && items.length && <div className={"outStading"}><strong> <SimpleText textKey="OutstandingBalance" /> <FormattedPrice price={balance} currencyInfo={items[0].currency} /></strong></div>}
        <h2 className="h3"><SimpleText textKey={textKeys.listHeader} /></h2>
        {!isPreview ? (
          <>
            {!!totalCount && (
              <>
                {children}
                <div className={styles.actionsBlock}>
                  <LoadMoreButton
                    className={btnStyles.btnBig}
                    textKey={textKeys.loadMore}
                    totalCount={totalCount}
                    loadedCount={items.length}
                    size={size}
                    loadNext={index => loadMore(index, filterRef.current)}
                  />
                  {actions}
                </div>
              </>
              )}
              {!totalCount && (
                <div className="msg-block">
                  <SimpleText textKey={textKeys.noItems} />
                </div>
              )}
          </>
        ) : children}
        {footer}
      </div>
    </div>
  );
};

OutstandingBalance.propTypes = {
  items: PropTypes.array,
  totalCount: PropTypes.number.isRequired,
  balance: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  loadMoreRoute: PropTypes.object.isRequired,
  textKeys: PropTypes.shape({
    introduction: PropTypes.string,
    listHeader: PropTypes.string.isRequired,
    loadMore: PropTypes.string.isRequired,
    noItems: PropTypes.string.isRequired,
  }).isRequired,
  actions: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node.isRequired,
  showAuthorizationStatusFilter: PropTypes.bool,
};

export default OutstandingBalance;

export const selectPropsFromPage = ({
  outstandings: {
    items,
    totalCount,
    balance
  },
  size,
}) => ({
  items: items,
  totalCount: totalCount,
  size,
  balance
});
