import styles from '../MainNav.module.scss';
import { memo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import MainNav from '../MainNav';
import NavItem from './NavItem';
import NavDimensionsProvider from '../NavDimensionsProvider';
import SublistPositionProvider from '../SublistPositionProvider';
import { useMultiColumnDropdownSelector, useIsMainNavItemsLoaded } from '../hooks';
import { hoveredListClass } from '../eventHandlers';
import { useHeaderContext } from 'components/sections/headerContext';

const rootMutationOptions = { attributes: true, attributeFilter: ['class'] };
const subtreeMutationOptions = { attributes: true, subtree: true, attributeFilter: ['aria-expanded'] };

const DesktopNavBase = ({ isDesignerMode }) => {
  const headerContext = useHeaderContext();
  const navItemsLoaded = useIsMainNavItemsLoaded();
  const navRef = useRef(null);
  const multiColumnDropdown = useMultiColumnDropdownSelector();

  useEffect(() => {
    if (!navItemsLoaded || !headerContext.available)
      return;

    const navListRootElement = navRef.current.firstElementChild;
    let isHovered = false;
    const navListRootObserver = new MutationObserver(_mutations => {
      // Sets desktop menu hover status in Header component for handling sticky header appearance.
      isHovered = navListRootElement.classList.contains(hoveredListClass);
      headerContext.setDesktopNavHoverStatus(isHovered);
      // Remove page height when navigation lost hovered status.
      if (!isHovered)
        document.body.style.height = '';
    });

    // Handle visibility of menu overflowed content in case menu sublists have height larger than current page height.
    const layout = document.getElementById('layout');
    let prevScrollHeight, removeResizeObserver;
    const navListSubtreeObserver = new MutationObserver(([mutation]) => {
      // Remove image resize observer in case submenu was closed.
      if (removeResizeObserver)
        removeResizeObserver();

      const isExpanded = mutation.target.getAttribute(mutation.attributeName) === 'true';
      if (multiColumnDropdown && isExpanded) {
        const imgWrapper = mutation.target.querySelector('.' + styles.imgWrapper);
        if (imgWrapper && !imgWrapper.classList.contains('lazy-load-image-loaded')) {
          // Add resize observer if multicolumn view image present and has not been loaded yet
          // so page height will change if necessary on image load (loading error).
          const resizeObserver = new ResizeObserver(([entry], observer) => {
            const handleResize = () => {
              if (!navRef.current)
                return;

              if (prevScrollHeight !== navRef.current.scrollHeight) {
                prevScrollHeight = navRef.current.scrollHeight;
                if (layout.scrollHeight !== layout.offsetHeight)
                  document.body.style.height = layout.scrollHeight + 'px';
              }
            };

            handleResize();

            if (!removeResizeObserver) {
              const img = entry.target.firstElementChild;
              // Do not add event listeners if placeholder gif image is still been shown.
              if (!img.classList.contains(styles.img))
                return;

              removeResizeObserver = e => {
                observer.disconnect();
                removeResizeObserver = null;
                img.removeEventListener('load', removeResizeObserver);
                img.removeEventListener('error', removeResizeObserver);
                if (e)
                  handleResize();
              };
              img.addEventListener('load', removeResizeObserver);
              img.addEventListener('error', removeResizeObserver);
            }
          });

          resizeObserver.observe(imgWrapper);
        }
      }

      // There is no changes to handle If menu scrollHeight has not been changed.
      if (prevScrollHeight === navRef.current.scrollHeight)
        return;

      prevScrollHeight = navRef.current.scrollHeight;
      if (isExpanded) {
        if (layout.scrollHeight !== layout.offsetHeight)
          document.body.style.height = layout.scrollHeight + 'px';
      } else {
        // If submenu of another submenu was closed and overflow is still present then do nothing to avoid scroll jumping.
        if (!multiColumnDropdown && layout.offsetHeight !== layout.scrollHeight)
          return;

        const listElement = mutation.target.parentElement;
        if (isHovered && listElement.parentElement === navListRootElement && window.pageYOffset > 0)
          // If second level submenu was closed and page was scrolled, pageYOffset should be added to page height value
          // to avoid scroll jumping when switching opened items on menu first level.
          document.body.style.height = window.innerHeight + window.scrollY + 'px';
        else
          document.body.style.height = '';
      }
    });

    navListRootObserver.observe(navListRootElement, rootMutationOptions);
    navListSubtreeObserver.observe(navListRootElement, subtreeMutationOptions);

    return () => {
      navListSubtreeObserver.disconnect();
      navListRootObserver.disconnect();
      removeResizeObserver && removeResizeObserver();
      headerContext.setDesktopNavHoverStatus(false);
    };
  }, [navItemsLoaded, multiColumnDropdown]);

  const nav = (
    <MainNav
      ref={navRef}
      NavItemComponent={NavItem}
      navClass={`${styles.desktop} ${multiColumnDropdown ? styles.multicolumn : styles.simple}`}
      isDesignerMode={isDesignerMode}
    />
  );

  return isDesignerMode || multiColumnDropdown
    ? nav
    : (
      <NavDimensionsProvider navRef={navRef}>
        <SublistPositionProvider>
          {nav}
        </SublistPositionProvider>
      </NavDimensionsProvider>
    );
};

DesktopNavBase.propTypes = {
  isDesignerMode: PropTypes.bool,
};

export default memo(DesktopNavBase);
