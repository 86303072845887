import styles from '../../Order.module.scss';
import PropTypes from 'prop-types';

//TICKET 118261:3.11.�Display HHT code:::ORDERDETAIL
const ProductHeaderRow = ({
  id,
  hhtCode,
  title,
  lineTexts,
  cancelled,
}) => {
  const valueClassName = `${styles.value} ${cancelled ? styles.cancelled : ''}`;

  return (
    <>
      {cancelled &&
        <tr className="visually-hidden">
          <td>
            <span className={styles.name}>{lineTexts.lineStatus}</span>
            <span className={valueClassName}>{lineTexts.lineStatusCancelled}</span>
          </td>
        </tr>
      }
      <tr>
        <td>
          <span className={styles.name}>{lineTexts.id}</span>
          <span className={valueClassName}>{id}</span>
        </td>
        <td>
          {/*TICKET 118261:3.11.�Display HHT code:::ORDERDETAIL*/}
          <span className={styles.name}>{lineTexts.hhtCode}</span>
          <span className={valueClassName}>{hhtCode}</span>
        </td>
        <td>
          <span className={styles.name}>{lineTexts.title}</span>
          <span className={valueClassName}>{title}</span>
        </td>
      </tr>
    </>
  );
};

//TICKET 118261:3.11.�Display HHT code:::ORDERDETAIL
ProductHeaderRow.propTypes = {
  id: PropTypes.string,
  hhtCode: PropTypes.string,
  title: PropTypes.string,
  lineTexts: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  cancelled: PropTypes.bool,
};

export default ProductHeaderRow;
