import styles from './Lines.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';
import { ProductThumbnail } from 'components/primitives/product';

//TICKET 158383 - Promotional Banner(Best Practices)
const MediaBlock = ({ productTitle, imageUrl, productUrl, route, noImageUrl, promotionalBanner }) => {
  if (productUrl) {
    return (
      <WithProductClickTracking>
        {handleClick => (
          <Link
            className={styles.productThumbnail}
            url={productUrl}
            to={route}
            tabIndex="-1"
            onClick={handleClick}
          >
            {/*TICKET 158383 - Promotional Banner(Best Practices)*/}
            <ProductThumbnail src={imageUrl} noImageSrc={noImageUrl} title={productTitle} promotionalBanner={promotionalBanner} />
          </Link>
        )}
      </WithProductClickTracking>
    );
  }
  return (
    <span className={styles.productThumbnail} aria-hidden>
      {/*TICKET 158383 - Promotional Banner(Best Practices)*/}
      <ProductThumbnail src={imageUrl} noImageSrc={noImageUrl} title={productTitle} promotionalBanner={promotionalBanner} />
    </span>
  );
};

MediaBlock.propTypes = {
  productTitle: PropTypes.string,
  imageUrl: PropTypes.string,
  productUrl: PropTypes.string,
  route: PropTypes.any.isRequired,
  noImageUrl: PropTypes.string,
  promotionalBanner: PropTypes.string,
};

export default connect(({ settings: { product } }) => ({
  noImageUrl: product ? product.noImage.small : null,
}))(MediaBlock);