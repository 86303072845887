import styles from './WishList.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsPreview } from 'components/objects/preview';

//TICKET 118261-3.11. Display HHT code:::WISHLIST
const ProductInfo = ({
  productUrl,
  route,
  productTitle,
  productId,
  hhtCode,
  description,
  variantTitle,
}) => {
  const isPreview = useIsPreview();

  return (
    <>
      {productUrl
        ? (
          <WithProductClickTracking>
            {handleClick => (
              <Link
                onClick={handleClick}
                url={productUrl}
                to={route}
                className={styles.largeTitle}
              >
                {productTitle}
              </Link>
            )}
          </WithProductClickTracking>
        )
        : <span className={styles.largeTitle}>{isPreview ? <Placeholder /> : productTitle}</span>
      }
      <div className={variantTitle ? styles.variantIdWrapper : null}>
        <div className={styles.productId}>
          {/*TICKET 118261-3.11. Display HHT code:::WISHLIST*/}
          <SimpleText textKey="HHT_Code" /> {hhtCode}
        </div>
      </div>
      {description && (
        <div className={styles.productDescription}>{description}</div>
      )}
    </>
  );
};

//TICKET 118261-3.11. Display HHT code:::WISHLIST
ProductInfo.propTypes = {
  productUrl: PropTypes.string,
  route: PropTypes.any.isRequired,
  productTitle: PropTypes.string,
  productId: PropTypes.string,
  hhtCode: PropTypes.string,
  description: PropTypes.string,
  variantTitle: PropTypes.string,
};

export default memo(ProductInfo);