import createRenderer from './createProfileRenderer';
import { Profile } from './myAccount';
import { Edit } from './editProfile';
import { ChangePassword } from './changePassword';
import { SubAccounts, SubAccount } from './subAccounts';
import { CreateProspect } from './createProspect';
import { CreateDocFreeReturnOrder } from './createDocFreeReturnOrder';
import { CreateDocBasedReturnOrder } from './createDocBasedReturnOrder';
import { routesBuilder, RouteName } from 'routes';
import { PageComponentNames } from 'behavior/pages';
import OrderTemplates from './orderTemplates';
import { List as SalesAgreements, Details as SalesAgreementDetails } from './salesAgreements';
import {
  Orders,
  Quotes,
  Invoices,
  ReturnOrders,
  CreditNotes,
  ReturnReceipts,
  Shipments,

  //TICKET-118266:3.16. Outstanding balance – Overview page:::PAGE
  Outstandings,

} from './documents';
import { Document } from './document';
import { OrderAuthorizations } from './orderAuthorizations';

const profileBackTextKeys = ['ButtonText_BackToProfile', 'AccountDashboard'];

export default {
  myAccount: createRenderer('MyAccount_Header', Profile, { linkKey: '/' }),
  changePassword: createRenderer('ChangePassword', ChangePassword, {
    linkKey: '/',
    backTo: {
      route: routesBuilder.forMyAccount(),
      texts: profileBackTextKeys,
    },
  }),
  [PageComponentNames.SubAccounts]: createRenderer('SubAccounts', SubAccounts, { linkKey: 'subAccounts' }),
  [PageComponentNames.SubAccount]: createRenderer('', SubAccount, {
    linkKey: 'subAccounts',
    backTo: {
      route: routesBuilder.forSubAccounts(),
      texts: ['ButtonText_BackToSubAccounts', 'ManageSubAccounts'],
    },
  }),
  [PageComponentNames.CreateProspect]: createRenderer('CreateProspect_Header', CreateProspect, { linkKey: 'createProspect' }),
  [PageComponentNames.OrderTemplates]: createRenderer('OrderTemplates_Header', OrderTemplates, { linkKey: 'templates' }),
  [PageComponentNames.SalesAgreements]: createRenderer('SalesAgreements_ListPageHeader', SalesAgreements, { linkKey: 'agreements' }),
  [PageComponentNames.Orders]: createRenderer('OrderHistory_OrderHistoryHeader', Orders, {
    linkKey: 'orders',
    backTo: {
      texts: profileBackTextKeys,
    },
  }),
  [PageComponentNames.Quotes]: createRenderer('QuoteHistory_Header', Quotes, {
    linkKey: 'quotes',
    backTo: {
      texts: profileBackTextKeys,
    },
  }),
  [PageComponentNames.Invoices]: createRenderer('InvoicesHistory_Header', Invoices, {
    linkKey: 'invoices',
    backTo: {
      texts: profileBackTextKeys,
    },
  }),
  [PageComponentNames.ReturnOrders]: createRenderer('ReturnOrderHistory_Header', ReturnOrders, {
    linkKey: 'returnOrders',
    backTo: {
      texts: profileBackTextKeys,
    },
  }),
  [PageComponentNames.CreditNotes]: createRenderer('CreditNotesHistory_Header', CreditNotes, {
    linkKey: 'creditNotes',
    backTo: {
      texts: profileBackTextKeys,
    },
  }),
  [PageComponentNames.ReturnReceipts]: createRenderer('ReturnReceiptsHistory_Header', ReturnReceipts, {
    linkKey: 'returnReceipts',
    backTo: {
      texts: profileBackTextKeys,
    },
  }),
  [PageComponentNames.Shipments]: createRenderer('ShipmentsHistory_Header', Shipments, {
    linkKey: 'shipments',
    backTo: {
      texts: profileBackTextKeys,
    },
  }),
  [PageComponentNames.Order]: createRenderer('', Document, {
    linkKey: 'orders',
    backTo: {
      route: routesBuilder.forOrders(),
      texts: ['ButtonText_BackToOrders', 'MyOrders'],
      supportedRoutes: new Set([RouteName.MyAccount, RouteName.Orders, RouteName.OrderAuthorizations]),
    },
  }),
  [PageComponentNames.Quote]: createRenderer('', Document, {
    linkKey: 'quotes',
    backTo: {
      route: routesBuilder.forQuotes(),
      texts: ['ButtonText_BackToQuotes', 'MyQuotes'],
      supportedRoutes: new Set([RouteName.Quotes]),
    },
  }),
  [PageComponentNames.Invoice]: createRenderer('', Document, {
    linkKey: 'invoices',
    backTo: {
      route: routesBuilder.forInvoices(),
      texts: ['ButtonText_BackToInvoices', 'MyInvoices'],
      supportedRoutes: new Set([RouteName.Invoices]),
    },
  }),
  [PageComponentNames.ReturnOrder]: createRenderer('', Document, {
    linkKey: 'returnOrders',
    backTo: {
      route: routesBuilder.forReturnOrders(),
      texts: ['ButtonText_BackToReturnOrders', 'MyReturnOrders'],
      supportedRoutes: new Set([RouteName.ReturnOrders]),
    },
  }),
  [PageComponentNames.CreditNote]: createRenderer('', Document, {
    linkKey: 'creditNotes',
    backTo: {
      route: routesBuilder.forCreditNotes(),
      texts: ['ButtonText_BackToCreditNotes', 'MyCreditNotes'],
      supportedRoutes: new Set([RouteName.CreditNotes]),
    },
  }),
  [PageComponentNames.ReturnReceipt]: createRenderer('', Document, {
    linkKey: 'returnReceipts',
    backTo: {
      route: routesBuilder.forReturnReceipts(),
      texts: ['ButtonText_BackToReturnReceipts', 'MyReturnReceipts'],
      supportedRoutes: new Set([RouteName.ReturnReceipts]),
    },
  }),
  [PageComponentNames.Shipment]: createRenderer('', Document, {
    linkKey: 'shipments',
    backTo: {
      route: routesBuilder.forShipments(),
      texts: ['ButtonText_BackToShipments', 'MyShipments'],
      supportedRoutes: new Set([RouteName.Shipments]),
    },
  }),
  [PageComponentNames.SalesAgreement]: createRenderer('', SalesAgreementDetails, {
    linkKey: 'agreements',
    backTo: {
      route: routesBuilder.forSalesAgreements(),
      texts: ['ButtonText_BackToSalesAgreements', 'MySalesAgreements'],
    },
  }),
  [PageComponentNames.EditProfile]: createRenderer('YourDetails', Edit, { linkKey: 'edit' }),
  [PageComponentNames.OrderAuthorizations]: createRenderer(
    'AuthorizationHistory',
    OrderAuthorizations,
    { linkKey: 'authorizations' },
  ),
  [PageComponentNames.CreateDocFreeReturnOrder]: createRenderer('ReturnOrder_Header', CreateDocFreeReturnOrder, {
    linkKey: 'returnOrders',
    backTo: {
      route: routesBuilder.forReturnOrders(),
      texts: ['ButtonText_BackToReturnOrders', 'MyReturnOrders'],
    },
  }),
  [PageComponentNames.CreateDocBasedReturnOrder]: createRenderer('', CreateDocBasedReturnOrder, {
    linkKey: 'returnOrders',
  }),

  //TICKET-118266:3.16. Outstanding balance – Overview page:::PAGE
  [PageComponentNames.Outstandings]: createRenderer('Outstandings_OutstandingsHeader', Outstandings, {
    linkKey: 'outstandings',
    backTo: {
      texts: profileBackTextKeys,
    },
  }),
};
